import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd'
import { DTransformconfigdt,Token } from '../../../api/BackEndURL'

let id = ''
let key = 'updatable'
export const DTransformconfigdtGet = createAsyncThunk("DTransformconfigdtGet", async () => {
        return await DTransformconfigdt.method.get(DTransformconfigdt.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})

export const DTransformconfigdtPost = createAsyncThunk("DTransformconfigdtPost", async (Data) => {
    return await DTransformconfigdt.method.post(`${DTransformconfigdt.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DTransformconfigdtGetOne = createAsyncThunk("DTransformconfigdtGetOne", async () => { 
       return await DTransformconfigdt.method.get(`${DTransformconfigdt.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DTransformconfigdtPut = createAsyncThunk("DTransformconfigdtPut", async (Data) => {
    return await DTransformconfigdt.method.put(`${DTransformconfigdt.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};
export const DTransformconfigdtReducer = createSlice({
    name: "DTransformconfigdt",
    initialState: {
        dTConfigdtGetData: [],
        dTConfigdtAddData: {
            config_name: '', 
            transform_name: '', 
            config_id: '',
            start_date: '',
            end_date: '',
            is_active: false,
          
        },
        dTConfigdtUpdate: {
            config_name: '', 
            transform_name: '', 
            // Dtranfig_id: '',
            start_date: '',
            end_date: '',
            is_active: '',
        },
        newForm: {
            DTconfigdtEditPop: false,
            DTconfigdtViewPop: false,
            DTconfigdtCreatePop: false,
            Search: true
        },
        loader: {
            Loader: false
        },
        singleData: "",
        getOneData: []
    },
    reducers: {
        DtconfigdtGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DTconfigdtAddForm: (state, action) => {
            state.newForm.Search = action.payload.Search
            state.newForm.DTconfigdtAddForm = action.payload.DTconfigdtAddForm
        },
        DTconfigdtEditPop: (state, action) => {
            state.newForm.DTconfigdtEditPop === false ? state.newForm.DTconfigdtEditPop = true : state.newForm.DTconfigdtEditPop = false
        },
        DTconfigdtViewPop: (state, action) => {
            state.newForm.DTconfigdtViewPop === false ? state.newForm.DTconfigdtViewPop = true : state.newForm.DTconfigdtViewPop = false
        },
        DTconfigdtCreatePop: (state, action) => {
            state.newForm.DTconfigdtCreatePop === false ? state.newForm.DTconfigdtCreatePop = true : state.newForm.DTconfigdtCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(DTransformconfigdtGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(DTransformconfigdtGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.dTConfigdtGetData = action.payload
        }) 
        builder.addCase(DTransformconfigdtGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(DTransformconfigdtPost.fulfilled, (state, action) => {
            createSuccess(true)
            state.newForm.DTconfigdtCreatePop=false;
            state.newForm.DTconfigdtAddForm=false;
            state.newForm.Search=true;
            state.dTConfigdtAddData.config_name = action.payload.config_name;
            state.dTConfigdtAddData.transform_name = action.payload.transform_name;
            state.dTConfigdtAddData.config_id = action.payload.config_id;
            state.dTConfigdtAddData.start_date = action.payload.start_date;
            state.dTConfigdtAddData.end_date = action.payload.end_date;
            state.dTConfigdtAddData.is_active = action.payload.is_active;
            state.newForm.DTconfigdtCreatePop = action.payload.DTconfigdtCreatePop;
            // state.dTConfigAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(DTransformconfigdtPut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.dTConfigdtAddData.config_name = action.payload.config_name;
            state.dTConfigdtAddData.transform_name = action.payload.transform_name;
            state.dTConfigdtAddData.config_id = action.payload.config_id;
            state.dTConfigdtAddData.start_date = action.payload.start_date;
            state.dTConfigdtAddData.end_date = action.payload.end_date;
            state.dTConfigdtUpdate.is_active = action.payload.is_active;
            state.newForm.DTconfigdtCreatePop = action.payload.DTconfigdtCreatePop
        })
    }
})

export const { DTconfigdtEditPop, DTconfigdtViewPop, DTconfigdtCreatePop, DtconfigdtGetId ,DTconfigdtAddForm} = DTransformconfigdtReducer.actions
export default (DTransformconfigdtReducer).reducer