import React, {useEffect} from 'react'
import  Header from '../../components/header/Header'
import CommonTable from '../../components/table/CommonTable'
import Footer from '../../components/footer/Footer'
import { useSelector, useDispatch } from 'react-redux'
import {DValidateAdd,DValidateCreatePop,DValidateEditPop,dValidateGet,DValidateViewPop,dValidateGetOne,DValidateGetId} from '../../redux/reducer/validate/DValidate'
//import ValidateAdd from '../../components/modal/validate/Add/ValidateAdd'
import ValidateEdit from '../../components/modal/validate/Edit/ValidateEdit'
import ValidatePost from '../../components/modal/validate/Add/ValidatePost'
import { Switch, Modal, Drawer, Button, Space } from 'antd';
import * as Fi from 'react-icons/fi';
import * as Fa from 'react-icons/fa';
import moment from 'moment'

function DValidate() {

  const dispatch = useDispatch()
    const SearchData = useSelector((state) => state.Header.Search.value)
    const footerPage = useSelector((state) => state.Header.Footer.page)
    const footerPageSize = useSelector((state) => state.Header.Footer.pagesize)
    const DValidateGetAll = useSelector((state) => state.DValidate.DValidateGetData)  
    // console.log(DValidateGetAll)
    const ValidateCreate = useSelector((state) => state.DValidate.newForm.DValidateCreatePop)     
    const ValidateEditModelOpen = useSelector((state) => state.DValidate.newForm.DValidateEditPop)                   

    useEffect(() => {
      dispatch(dValidateGet(true))
  }, [])

  const ValidateCreateModal = () => {
    dispatch(DValidateCreatePop())
}

const validateEditModal = () => {
    dispatch(dValidateGetOne(true))
    dispatch(DValidateEditPop())
}

  const validateColumn = [
    {
        title: 'S.No',
        dataIndex: 'sno',
        width: '50px',
        render: (text, object, index) => { return index + 1 },
        filteredValue: [SearchData],
        onFilter: (value, record) => {
            return String(record.transform_name).toLowerCase().includes(value.toLowerCase());
        }
    },
    {
        title: 'Table Name',
        dataIndex: 'tname',
        width: '230px',
    },
    {
        title: 'Configuration Name',
        dataIndex: 'config_name',
        // sorter: (a, b) => { return a.config_name.localeCompare(b.config_name) },
        width: '250px',
    },

        
    {
        title: 'Start Date',
        dataIndex: 'sdate',
        // sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
        width: '220px'
    },
    {
        title: 'End Date',
        dataIndex: 'edate',
        // sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
        width: '220px'
    },
    {
        title: 'Active',
        dataIndex: 'active',
        width: '90px'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        width: '70px'
    },
];

const dVali = DValidateGetAll.map((value) => {
  // console.log(value.transform_name)
  return (
      {
          tname : value.transform_name,
          config_name: value.config_name,
          sdate: moment.utc((value.start_date)).format('MM-DD-YYYY'),
          edate: moment.utc((value.end_date)).format('MM-DD-YYYY'),
          active: (<Switch checked={value.is_active === true ? true : false} />),
          action: (
              <div className='Action_Icons'>
                  {/* {configurationAccess.map((val, key) => { */}
                      {/* return ( */}
                          <Fi.FiEdit size={16} 
                          onClick={() => {
                              dispatch(DValidateGetId({ singleData: value.id }))
                              dispatch(dValidateGetOne())
                              dispatch(DValidateEditPop())
                          }} style={{ cursor: 'pointer', marginRight: '10px' }} 
                          />
                      {/* ) */}
                  {/* })} */}
                  <Fa.FaEye size={18} 
                  onClick={() => {
                      dispatch(DValidateGetId({ singleData: value.id }))
                      dispatch(dValidateGetOne())
                      dispatch(DValidateViewPop())
                  }} style={{ cursor: 'pointer', marginRight: '20px' }} 
                  />
              </div>
          )

      }
  )
})
console.log(dVali)

const DataValidate = (page, pageSize) => {
  return dVali.slice((page - 1) * pageSize, page * pageSize);
};

  return (
    <>
     <Header />
     <CommonTable ValidateColumn={validateColumn} ValidateData={DataValidate(footerPage, footerPageSize)}/>
     <Drawer title=" Create D-Validate" open={ValidateCreate} maskClosable={false} onClose={ValidateCreateModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={ValidateCreateModal}>Cancel</Button>
                    </Space>
                }
            >
                {/* <ValidateAdd/> */}
                <ValidatePost />
            </Drawer>

            <Drawer title="Validate Edit" open={ValidateEditModelOpen} closable={true}  maskClosable={false} onClose={validateEditModal} ariaHideApp={false} width='75vh'
               footer={[ 
                <div style={{padding:'12px 50px',display:'flex',justifyContent:'flex-end',margin:'0% auto',marginTop:'8px'}} >
                
                </div>]}
                footerStyle={{backgroundColor:'#f5f5fd'}}
                // extra={
                //     <Space>
                //     <Button onClick={pipelineEditModal}>Cancel</Button>
                //         {/* <Cg.CgClose size={20}  onClick={pipelineEditModal} style={{cursor:"pointer",color:"red"}}/> */}
                //     </Space>
                // }
            >
                <ValidateEdit />
            </Drawer>
     <Footer DataValidate={DValidateGetAll.length}/>
    </>
  )
}

export default DValidate
