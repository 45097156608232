import React from "react";
import { Modal } from "antd";
import { Table } from "antd";
import { useSelector } from "react-redux";

const MonitorDataStatusModalColumn = [
  {
    title: "S.No.",
    dataIndex: "sNo",
    width: "auto",
    onFilter: (value, record) => {
      return String(record.name).toLowerCase().includes(value.toLowerCase());
    },
  },

  {
    title: "ID",
    dataIndex: "id",
    width: "auto",
  },
  {
    title: "Pipline Detail Name",
    dataIndex: "pipeline_detail_name",
    width: "auto",
  },
  {
    title: "Pipline Name",
    dataIndex: "pipeline_name",
    width: "auto",
  },
  {
    title: "Source table Name",
    dataIndex: "source_table_name",
    width: "auto",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    width: "auto",
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    width: "auto",
  },
];

const MonitorDataStatusModal = ({
  isModalOpen,
  toggleModal,
  selectedRowData,
}) => {
  const MonitorDataStatus = useSelector(
    (state) => state.PipelineDetail.getOneData
  );
  const monitorGetData = (page, pageSize) => {
    return MonitorDataStatus.map((row, index) => ({
      sNo: index + 1,
      ...row,
    })).slice((page - 1) * pageSize, page * pageSize);
  };
  return (
    <>
      <Modal
        title="Description"
        open={isModalOpen}
        onOk={toggleModal}
        onCancel={toggleModal}
        width={1000}
        footer={false}
      >
        <Table
          className="Connection_Table"
          columns={MonitorDataStatusModalColumn}
          dataSource={monitorGetData(1, 7)} //Mock data
          pagination={true}
        />
      </Modal>
    </>
  );
};

export default MonitorDataStatusModal;
