import React from "react";
import "antd-css-utilities/utility.min.css";
import './styles/App.less'
import NavigateRoutes from "./Routes/Routes";

function App() {

  return (

    <>
      <NavigateRoutes />
    </>
    
  );
}
export default App;