import React,{useEffect, useMemo, useState} from 'react'
import './css/PreValidate.css'
import {Button, Select, message} from 'antd'
import SuccessMessage from './js/SuccessMessage'
import axios from 'axios'
import {URL} from '../../../../api/BackEndURL'
import Spinner from '../../atoms/spinner'

const PreValidate = (props) => {
    const{setModal,data}=props
    const key = 'updatable';
    const [listOpen,setListOpen]=useState({})
    const [success,setSuccess]=useState(false)
    const [getColumnList,setGetColumnList]=useState([])
    const [inValidColumns,setInValidColumns]=useState([])
    const [updateProp,setUpdateProp]=useState({})
    const listOfColumns=inValidColumns?.length!==0&&(![null,undefined,""].includes(inValidColumns))?inValidColumns:getColumnList||[{}]

    const updateSuccess = (msg) => {
        message.success({ content:msg, key, duration: 1, });
    };

    const closeModal=()=>{
        setSuccess(false)
        setModal(false)
        setListOpen({})
        setInValidColumns([])
        setGetColumnList([])
    }

    useEffect(()=>{
        if(Object?.values(data)?.length>0){
        axios?.post(`${URL}/pipeline_prevalidates/`,{
            page_id:data?.pageId,
            id:data?.id
        })?.then((res)=>{
            console.log('pipeline-details-pre-validation-SUCCESS',res?.data?.at(0)?.table_column)
            setGetColumnList(res?.data?.at(0)?.table_column)
        })?.catch((err)=>{
            console.log('pipeline-details-pre-validation-ERROR',err)
        })
    }
    },[data])

    const [selectedDataTypes,setSelectedDataTypes]=useState(listOfColumns)

    useEffect(()=>{
        if(listOfColumns?.length!==0){
            setSelectedDataTypes(listOfColumns)
        }
    },[listOfColumns])

    const listOpenAndClose=(name)=>{
        // setListOpen({...listOpen,[name]:listOpen?.[name]?false:true})
        setListOpen({[name]:listOpen?.[name]?false:true})
    }

    const selectOptionGenerator=(options)=>{
        return options&&options?.map((value)=>{
            return{
                value: value,
                label: value,
            }
        })
    }

    const targetHandleSelect=(select,tableName,source,columnName)=>{
        let finalVal={}
        const prev=selectedDataTypes&&selectedDataTypes?.filter(prevValue=>{
            if(tableName?.toLowerCase()!==prevValue?.table_name?.toLowerCase()){
                return prevValue
            }
        })
        if(select){
            let obj={}
            let columnObj={}
            selectedDataTypes&&selectedDataTypes?.forEach((values)=>{
                if(values?.table_name?.toLowerCase()===tableName?.toLowerCase()){
                    obj={table_name:values?.table_name,columns:[...values?.columns]}
                }
            })

            let prevColumn=obj?.columns&&obj?.columns?.filter((val)=>{
                if(val?.COLUMN_NAME?.toLowerCase()!==columnName?.toLowerCase()){
                    return val
                }
            })
            obj?.columns&&obj?.columns?.forEach((value)=>{
                if(value?.COLUMN_NAME?.toLowerCase()===columnName?.toLowerCase()){
                    // columnObj={...value,TARGETDATATYPE:select}  
                    columnObj={
                        COLUMN_NAME:value?.COLUMN_NAME,
                        SOURCE_DATA_TYPE:value?.SOURCE_DATA_TYPE,
                        TARGETDATATYPE:select
                    }                  
                }
            })
            if(Object?.values(obj)?.length>0&&Object?.values(columnObj)?.length>0){
                finalVal={...obj,columns:[...prevColumn,columnObj]}
            }

        }
        setSelectedDataTypes([...prev,finalVal])
    }

    const validate=()=>{
        if(inValidColumns?.length===0){
        axios?.post(`${URL}/validatingDatatype/`,{
            page_id:data?.pageId,
            id:data?.id,
            table_column:selectedDataTypes
        })?.then((res)=>{
            console.log('pre-validation SUCCESS',res)
            if(res?.data?.type?.toLowerCase()=='success'){
                setSuccess(true)
            }else if(res?.data?.type?.toLowerCase()==='invalid'){
                    setInValidColumns(res?.data?.data)
                    setUpdateProp(res?.data)
            }
        })
    }else{
        axios?.put(`${URL}/updatingvalidation/${updateProp?.id}`,{
            target_table_columns:selectedDataTypes
        })?.then((res)=>{
            console.log('Pre-validate-update-SUCCESS',res)
            updateSuccess(res?.data?.message)
            closeModal()
        })?.catch((err)=>{
            console.log('Pre-validate-update-ERROR',err)
        })
    }
    }

    const buttonDisabler=useMemo(()=>{
        let arr=[]
        selectedDataTypes&&selectedDataTypes?.forEach((item)=>{
            item?.columns&&item?.columns?.forEach((col)=>{
                arr?.push(col?.TARGETDATATYPE)
            })
        })
        if(!arr?.includes("")&&!arr?.includes('')&&!arr?.includes(undefined)&&!arr?.includes(null)&&arr?.length===inValidColumns?.length){
            return false
        }else{
            return true
        }
    },[selectedDataTypes])

  return (
    <div className='pre-validate-parent-container'>
        <p className='pre-validate-title'>Pre-Validation</p>

        <Spinner size={'lg'} data={listOfColumns}/>

        {inValidColumns?.length>0 &&<p className='pre-validate-error-title'>The Below Column's unable to migrate due to un-supported datatype</p>}
        <div className='pre-validate-table-parent-container'>
                    {!success&&<div className='pre-validate-table-inner-parent-container'>
                       {listOfColumns && listOfColumns?.map((tables,key)=>{
return(
    <React.Fragment>
    <p className={'pre-validate-table-title-close-state'} onClick={()=>listOpenAndClose(tables?.table_name)}>{tables?.table_name}</p>
{listOpen?.[tables?.table_name] && 
<table className='pre-validate-table-comp'>
        <tr className='pre-validate-table-tr'>
            <th className='pre-validate-table-th' >Column Name</th>
            <th className='pre-validate-table-th'>Source Data Type</th>
            <th className='pre-validate-table-th'>Target Data Type</th>
        </tr>
        {tables?.columns&&tables?.columns?.map((column,index)=>{
        return( <tr className='pre-validate-table-tr'>
                <td className='pre-validate-table-td pre-validate-width-200'>{column?.COLUMN_NAME}</td>
                <td className='pre-validate-table-td pre-validate-width-200'>{column?.SOURCE_DATA_TYPE}</td>
                <td className='pre-validate-table-td pre-validate-width-200'>
                <Select
                disabled={column?.OPTIONS&&column?.OPTIONS?.length>0?false:true}
                    defaultValue="Default"
                    style={{
                        width: '100%',
                    }}
                    onChange={(select)=>targetHandleSelect(select,tables?.table_name,column?.SOURCE_DATA_TYPE,column?.COLUMN_NAME)}
                     options={selectOptionGenerator(column?.OPTIONS)}
                />
                </td>
            </tr>
             )
            })}
    </table>}
    </React.Fragment>)})} 
                </div>}

                {success&&<SuccessMessage message={'These Columns are able to migrate'} onClick={closeModal}/>}
        </div>

       {!success&&<div className='pre-validate-table-button-container'>
        <Button className='pre-validate-table-buttons cancelButton' onClick={closeModal}>Cancel</Button>
        {inValidColumns?.length<1&&<Button className='pre-validate-table-buttons' type='primary' onClick={validate}>Validate</Button>}
        {inValidColumns?.length>0 &&<Button disabled={buttonDisabler} className='pre-validate-table-buttons' type='primary' onClick={validate}>Update</Button>}
        </div>}
    </div>
  )
}

export default React.memo(PreValidate)