import React, { useState, useRef } from 'react'
import { Button, Input, Form, Row, Col, DatePicker, Switch, message, Modal, AutoComplete, Table } from 'antd';
import moment from "moment"
import { useDispatch, useSelector } from 'react-redux'
import { DVisualPost, connectionReducer } from '../../../../../redux/reducer/Visualization/DVisualization';
// import { useEffect } from 'react';
// import axios from 'axios';
import '../../Visual.css'
// import Tableau from './Tableau'
// import PowerBi from './PowerBi';

function Visual() {

  const dispatch = useDispatch()
  const ConfigAddData = useSelector((state) => state.DVisual.Data)



  const { RangePicker } = DatePicker;
  const [visualModalOpen, setvisualModalOpen] = useState(false);
  const [vdataModalOpen, setVdataModalOpen] = useState(false);
  const [visualString, setVisualString] = useState([{ id: 0, keyPrams: "" }])
  const [vdataTypes, setVdataTypes] = useState([{ id: 0, datatypes: "" }])
  const [vpreDefineErr, setVpreDefineErr] = useState(false)
  const [vcount, setVcount] = useState(1)
  const [vdataTypeCount, setVdataTypeCount] = useState(1)
  const [visualStrErr, setVisualStrErr] = useState(false)
  const [configanmelist, setConfignamelist] = useState(false);
  const [logoname, setlogoname] = useState([])
  const [imgname, setImgname] = useState('');
  const [isActive, setIsActive] = useState(true)
  const [dates, setDates] = useState([])
  const [newconfigname, setNewConfigname] = useState('')
  const [description, setDescription] = useState('')


 

  const CreateConfiguration = async (e) => {
    // const keyParam = connString.pop()
    // console.log(keyParam)
    // console.log(connString, isActive)
    if (/^[\w\s-]+$/.test(description) && visualString.length > 0 && vdataTypes.length > 1) {
      setConfignamelist(false)
        setVisualStrErr(false)
        setVpreDefineErr(false)
        dispatch(DVisualPost({
          dvisual_config_name: newconfigname,
            logo_name: imgname,
            key_param: visualString,
            d_type: vdataTypes,
            description: description,
            start_date: moment(dates[0]).format('YYYY-MM-DD'),
            end_date: moment(dates[1]).format('YYYY-MM-DD'),
            is_active: isActive
        }))


    }
    else {
        // if (connames.length < 1) {
        //     setConanmelist(false)
        // }
        // else {
          setConfignamelist(true)
        // }
        // if (connString.length < 0) {
        //     setConSetErr(false)
        // }
        // else {
          setVisualStrErr(true)
        // }
        // if (dataTypes.length < 1) {
        //     setPreDefineErr(false)
        // }
        // else {
          setVpreDefineErr(true)
        // }
    }
}


  const visualModal = () => {
    setvisualModalOpen(true);
  };

  const visualdataModal = () => {
    setVdataModalOpen(true);
};

  const AddVisualString = () => {
    setVcount(vcount + 1)
    // console.log(count)
    setVisualString([...visualString, { id: vcount, keyPrams: "" }]);
    // console.log(setConnString)
};

const AddvisualDatatypes = () => {
  setVdataTypeCount(vdataTypeCount + 1)
  // console.log(dataTypeCount)
  setVdataTypes([...vdataTypes, { id: vdataTypeCount, datatypes: "" }]);
  // console.log(setDataTypes)
};

const handleKeyParams = (e, index) => {
  const { name, value } = e.target;
  const visualstrglist = [...visualString];
  visualstrglist[index][name] = value
  setVisualString(visualstrglist)
}

const handleVdatatypes = (e, index) => {
  const { name, value } = e.target;
  const vdatatypeslist = [...vdataTypes];
  vdatatypeslist[index][name] = value
  setVdataTypes(vdatatypeslist)
}

const RemoveVisualString = (index) => {
  // console.log(index)
  const visualstrglist = [...visualString];
  // console.log(connstrglist)
  visualstrglist.pop(index, 1);
  setVisualString(visualstrglist);
};

const RemoveVdatatypes = (index) => {
  // console.log(index)
  const vdatatypelist = [...vdataTypes];
  // console.log(datatypelist)
  vdatatypelist.pop(index, 1);
  setVdataTypes(vdatatypelist);
};

const handleOk = () => {
  setvisualModalOpen(false);
  setVdataModalOpen(false)
  if (visualString.length > 0) {
    setVisualStrErr(false)
  }
  if (setVdataTypes.length > 0) {
    setVpreDefineErr(false)
  }

};
const handleCancel = () => {
  setvisualModalOpen(false);
  setVdataModalOpen(false)
  setVisualString([{ id: 0, keyPrams: "" }])
  setVdataTypes([{ id: 0, datatypes: "" }])
};
  const switchOnChange = (checked) => {
    console.log(`switch to ${checked}`);
    setIsActive(checked)
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day')
  };
  // const datePickerOnChange = (dates, dateStrings) => {
  //   setTableData({ ...tableData, end_date: dateStrings[1], start_date: dateStrings[0] })
  // };
  // const [selectedOption, setSelectedOption] = useState(null);

  // const handleLogoNameChange = (value) => {
  //   setSelectedOption(value);
  // };
  // const logoNameOptions = ['Tableau', 'PowerBi'];


  function imagenames(r) {
    const imagelist = [];
    r.keys().map((item, index) => {
        imagelist.push(item.replace('./', ''));
    })
    return imagelist;
}


const disableDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().startOf('day')
};

  const imagename = imagenames(require.context('../../Vlogoimg', false, /\.(png|jpe?g|svg)$/));
  //dropdown imglist for Autocomplete options
  {
      imagename.map((list, key) => {
          console.log(list)
          const imageNameWithoutExtension = list.replace(/\.(png|jpe?g|svg)$/i, '');
          logoname.push({ value: imageNameWithoutExtension })
      }
      )
  }

  function onSelect(value) {
    setImgname(value)
}

  const Configname = (e) => {
    console.log(e.target.value)
    const confignames = [];
    ConfigAddData.map((value) => { confignames.push(value.connection_name) })
    console.log(confignames)
    const same = confignames.includes(e.target.value)
    if (e.target.value !== 0 && same) {
        return setConfignamelist(true)
    }
    else {
      setConfignamelist(false)
        return setNewConfigname(e.target.value)

    }
}

  return (
    <>
      <div className='NewTableau-Container'>
        <Form
          name="basic"
          autoComplete="off"
          layout='vertical'
          className='tableau-Form-Container'
          style={{ fontFamily: 'Nunito' }}
        >
          <Row gutter={[16, 0]}>
            <Col lg={{ span: 12, offset: 0 }}>
              <Form.Item label="Cofiguration Name" name="Connection Name" initialValue={newconfigname}>
                <Input style={{ textTransform: 'capitalize' }} placeholder='Enter Your Cofiguration Name' onChange={(e) => (Configname(e))} />
                {configanmelist && <p className='error'>Configuration name already exist</p>}
              </Form.Item>
            </Col>
            <Col lg={{ span: 5, offset: 4 }}>
              <Form.Item label="Active" name="is_active">
                <Switch onChange={switchOnChange} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 12, offset: 0 }}>
              <Form.Item label="Image Name " name="connection_name" rules={[{ required: true, message: 'Enter Your Logo Name' }, { pattern: /(.*[a-z]){0}/, message: 'Field must be at least 3 characters' }]}>
                <AutoComplete className='configname-inputfeild' options={logoname} onSelect={onSelect} placeholder="Enter Your Logo Name" />
              </Form.Item>
            </Col>

            <Col lg={{ span: 12, offset: 0 }}>
              <Form.Item className="reg-lbl" label="Description" name="Description" rules={[{ required: true, message: 'Enter Your Description' }, { pattern: /^[\w\s-]+$/, message: 'Field does not allow special characters' }]}>
                <Input style={{ textTransform: 'capitalize' }} className='newCon-input-feild' placeholder='Enter Your Description' onChange={(e) => (setDescription(e.target.value))}  />
              </Form.Item>
            </Col>

            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
              <Form.Item className="reg-lbl" label="Start Date - End Date" name="StartDatetoEndDate" initialValue={dates} rules={[{ required: true, message: 'Enter Your End Date' },]}>
                {/* <RangePicker
                  style={{ width: "100%" }}
                  defaultValue={[moment()]}
                  disabledDate={disabledDate}
                  onCalendarChange={(value) => setTableData({ ...tableData, end_date: value })}
                  value={tableData.end_date}
                /> */}
                <RangePicker disabledDate={disabledDate} defaultValue={[moment()]} disableDate={disableDate} 
                                        onCalendarChange={(value) => {
                                        console.log(value);
                                        setDates([value[0], value[1]]);
                                }}

                                    value={dates}
                                    style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col lg={{ span: 14, offset: 0 }} >
              <Button className='popUp_btn' onClick={visualModal} >Add Visual String</Button>
              {visualStrErr && <p className='error'>Please fill Visual String </p>}
            </Col>
            <Col lg={{ span: 10, offset: 0 }} >
              <Button className='popUp_btn'   onClick={visualdataModal}>Pre-Defined DataTypes</Button>
              {vpreDefineErr && <p className='error'>Please fill Pre-Defined DataTypes</p>}
            </Col>




            <Modal title='Connection String Parameter' style={{ top: 50 }} open={visualModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ height: 300 }} >

              <div className='conDtype_Acc'>
                {/* <label className='Conn_Title'>Key Parameter </label> */}
                {visualString.map((x, i) => {
                  return (
                    <div className='flex-twocontainer'>
                      {/* <div className='conn-accordian'> */}
                      <input autoFocus={true} placeholder='Ex: id,user,password, etc.' style={{ textTransform: 'capitalize' }} className='key-inputfeild' name='keyPrams' value={x.keyPrams} onChange={(e) => { handleKeyParams(e, i) }} />
                      {/* </div> */}

                      <div className='addrmv-btn-cont'>
                        {visualString.length - 1 === i && x.keyPrams != "" && <button onClick={AddVisualString}>+</button>}
                        {visualString.length !== 1 && <button onClick={RemoveVisualString}>-</button>}
                      </div>
                    </div>
                  )
                })}
              </div>

            </Modal>



            <Modal title='Pre-Defined Datatypes' style={{ top: 50 }} open={vdataModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ height: 300 }} >
                                <div className='conDtype_Acc'>
                                    {/* <label className='Conn_Title'>DataType </label> */}
                                    {vdataTypes.map((x, i) => {
                                        return (
                                            <div className='flex-twocontainer'>
                                                {/* <div className='conn-accordian'> */}
                                                <input autoFocus={true} placeholder='ex: int,float,str,etc.' style={{ textTransform: 'capitalize' }} className='key-inputfeild' name='datatypes' value={x.datatypes}  onChange={(e) => { handleVdatatypes(e, i) }} />
                                                {/* </div> */}

                                                <div className='addrmv-btn-cont'>
                                                    {vdataTypes.length - 1 === i && x.datatypes != "" && <button onClick={AddvisualDatatypes}>+</button>}
                                                    {vdataTypes.length !== 1 && <button onClick={RemoveVdatatypes}>-</button>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Modal>


                            
                        <div className='newvisual-btn-cont'>
                            <Button htmlType='submit' type='primary' onClick={CreateConfiguration}>Create Connection</Button>

                        </div>

            {/* {selectedOption === 'Tableau' && <Tableau tableData={tableData} setTableData={setTableData} />}
            {selectedOption === 'PowerBi' && <PowerBi />} */}
          </Row>
        </Form>
      </div>
    </>
  );
}








export default Visual