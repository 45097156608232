import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd'
import { DTransformconfig,Token } from '../../../api/BackEndURL'

let id = ''
let key = 'updatable'
export const DTransformconfigGet = createAsyncThunk("DTransformconfigGet", async () => {
        return await DTransformconfig.method.get(DTransformconfig.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})

export const DTransformconfigPost = createAsyncThunk("DTransformconfigPost", async (Data) => {
    return await DTransformconfig.method.post(`${DTransformconfig.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DTransformconfigGetOne = createAsyncThunk("DTransformconfigGetOne", async () => { 
       return await DTransformconfig.method.get(`${DTransformconfig.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DTransformconfigPut = createAsyncThunk("DTransformconfigPut", async (Data) => {
    return await DTransformconfig.method.put(`${DTransformconfig.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};
export const DTransformconfigReducer = createSlice({
    name: "DTransformconfig",
    initialState: {
        dTConfigGetData: [],
        dTConfigAddData: {
            config_name: '', 
            transform_name: '', 
            config_id: '',
            start_date: '',
            end_date: '',
            is_active: false,
          
        },
        dTConfigUpdate: {
            config_name: '', 
            transform_name: '', 
            // Dtranfig_id: '',
            start_date: '',
            end_date: '',
            is_active: '',
        },
        Tconfig: {
            DTconfigEditPop: false,
            DTconfigViewPop: false,
            DTconfigCreatePop: false,
            Search: true
        },
        loader: {
            Loader: false
        },
        singleData: "",
        getOneData: []
    },
    reducers: {
        DtconfigGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DTconfigAddForm: (state, action) => {
            state.Tconfig.Search = action.payload.Search
            state.Tconfig.DTconfigAddForm = action.payload.DTconfigAddForm
        },
        DTconfigEditPop: (state, action) => {
            state.Tconfig.DTconfigEditPop === false ? state.Tconfig.DTconfigEditPop = true : state.Tconfig.DTconfigEditPop = false
        },
        DTconfigViewPop: (state, action) => {
            state.Tconfig.DTconfigViewPop === false ? state.Tconfig.DTconfigViewPop = true : state.Tconfig.DTconfigViewPop = false
        },
        DTconfigCreatePop: (state, action) => {
            state.Tconfig.DTconfigCreatePop === false ? state.Tconfig.DTconfigCreatePop = true : state.Tconfig.DTconfigCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(DTransformconfigGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(DTransformconfigGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.dTConfigGetData = action.payload
        }) 
        builder.addCase(DTransformconfigGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(DTransformconfigPost.fulfilled, (state, action) => {
            createSuccess(true)
            state.Tconfig.DTconfigCreatePop=false;
            state.Tconfig.DTconfigAddForm=false;
            state.Tconfig.Search=true;
            state.dTConfigAddData.config_name = action.payload.config_name;
            state.dTConfigAddData.transform_name = action.payload.transform_name;
            state.dTConfigAddData.config_id = action.payload.config_id;
            state.dTConfigAddData.start_date = action.payload.start_date;
            state.dTConfigAddData.end_date = action.payload.end_date;
            state.dTConfigAddData.is_active = action.payload.is_active;
            state.Tconfig.DTconfigCreatePop = action.payload.DTconfigCreatePop;
            // state.dTConfigAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(DTransformconfigPut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.dTConfigAddData.config_name = action.payload.config_name;
            state.dTConfigAddData.transform_name = action.payload.transform_name;
            state.dTConfigAddData.config_id = action.payload.config_id;
            state.dTConfigAddData.start_date = action.payload.start_date;
            state.dTConfigAddData.end_date = action.payload.end_date;
            state.dTConfigUpdate.is_active = action.payload.is_active;
            state.Tconfig.DTconfigCreatePop = action.payload.DTconfigCreatePop
        })
    }
})

export const { DTconfigEditPop, DTconfigViewPop, DTconfigCreatePop, DtconfigGetId ,DTconfigAddForm} = DTransformconfigReducer.actions
export default (DTransformconfigReducer).reducer