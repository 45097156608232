import React, { useState, useRef } from 'react'
import { Button, Input, Form, Row, Col, DatePicker, Switch, message, Modal, AutoComplete, Table, Select, Upload } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DFlattenPost, connectionReducer } from '../../../../../redux/reducer/Dflatten/DFlatten';
import { upload } from '@testing-library/user-event/dist/upload';

function Dflatten() {
    const [newfilename, setNewFilename] = useState('')
    const [file, setFile] = useState('')
    const [filenamelist, setFilenamelist] = useState(false);
    const [isActive, setIsActive] = useState(true)
    const [selectname, setSelectname] = useState('');
    const [description, setDescription] = useState('')
    const [dates, setDates] = useState([])
    const dispatch = useDispatch()
    const { RangePicker } = DatePicker;
    const FileAddData = useSelector((state) => state.DFlatten.Data)
    const connames = []

    const CreateFlatten = async (e) => {
        if (/^[\w\s-]+$/.test(description)) {
            setFilenamelist(false);
            const formData = new FormData();
            formData.append('file_name', newfilename);
            formData.append('file_type', selectname);
            formData.append('input_path', file);
            formData.append('output_path', 'data.csv');
            formData.append('description', description);
            formData.append('start_date', moment(dates[0]).format('YYYY-MM-DD'));
            formData.append('end_date', moment(dates[1]).format('YYYY-MM-DD'));
            formData.append('is_active', isActive);
            dispatch(DFlattenPost(formData));
        } else {
            setFilenamelist(true);
        }
    }


    const Filename = (e) => {
        console.log(e.target.value)
        const filenames = [];
        FileAddData.map((value) => { filenames.push(value.connection_name) })
        console.log(filenames)
        const same = filenames.includes(e.target.value)
        if (e.target.value !== 0 && same) {
            return setFilenamelist(true)
        }
        else {
            setFilenamelist(false)
            return setNewFilename(e.target.value)

        }
    }

    const switchOnChange = (checked) => {
        console.log(`switch to ${checked}`);
        setIsActive(checked)
    };

    const onSelect = (value) => {
        setSelectname(value)
    }
    const disableDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day')
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day')
    };

    const customRequest = ({ file, onSuccess }) => {
        const allowedExtensions = {
            json: ['.json'],
            avro: ['.avro'],
            parquet: ['.parquet'],
            orc: ['.orc']
        };

        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        if (selectname in allowedExtensions && allowedExtensions[selectname].includes(fileExtension)) {
            setFile(file);
            onSuccess();
            console.log(file);
        } else {
            message.error(`Invalid file type for ${selectname}. Please select a ${selectname.toUpperCase()} file.`);
        }
    };




    return (
        <>
            <div className='NewTableau-Container'>
                <Form name="basic" autoComplete="off" layout='vertical' className='tableau-Form-Container' style={{ fontFamily: 'Nunito' }} onFinish={(values) => {
                    console.log('Form values:', values);
                }}>
                    <Row gutter={[16, 0]}>
                        <Col lg={{ span: 12, offset: 0 }}>
                            <Form.Item label="File Name" name="file_name" initialValue={newfilename}
                                rules={[{ required: true, message: 'Enter Your File Name' }]}>
                                <Input style={{ textFlatten: 'capitalize' }} placeholder='Enter Your File Name' onChange={(e) => (Filename(e))} />
                            </Form.Item>
                        </Col>

                        <Col lg={{ span: 5, offset: 6 }}>
                            <Form.Item label="Active" name="is_active">
                                <Switch onChange={switchOnChange} />
                            </Form.Item>
                        </Col>

                        <Col lg={{ span: 12, offset: 0 }}>
                            <Form.Item
                                label="File Type"
                                name="file_type"
                                initialValue={selectname}
                                rules={[
                                    { required: true, message: 'Enter Your File Type' },
                                ]}
                            >
                                <Select
                                    placeholder="Select Your File Type"
                                    style={{ textFlatten: 'capitalize' }}
                                    onSelect={onSelect}
                                >
                                    <Select.Option value="json">JSON</Select.Option>
                                    <Select.Option value="avro">AVRO</Select.Option>
                                    <Select.Option value="parquet">PARQUET</Select.Option>
                                    <Select.Option value="orc">ORC</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={{ span: 12, offset: 0 }}>
                            <Form.Item className="reg-lbl" label="Description" name="description" rules={[{ required: true, message: 'Enter Your Description' }, { pattern: /^[\w\s-]+$/, message: 'Field does not allow special characters' }]}>
                                <Input style={{ textFlatten: 'capitalize' }} className='newCon-input-feild' placeholder='Enter Your Description' onChange={(e) => (setDescription(e.target.value))} />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
                            <Form.Item className="reg-lbl" label="Start Date - End Date" name="StartDatetoEndDate" initialValue={dates} rules={[{ required: true, message: 'Enter Your End Date' },]}>
                                {/* <RangePicker
                  style={{ width: "100%" }}
                  defaultValue={[moment()]}
                  disabledDate={disabledDate}
                  onCalendarChange={(value) => setTableData({ ...tableData, end_date: value })}
                  value={tableData.end_date}
                /> */}
                                <RangePicker disabledDate={disabledDate} defaultValue={[moment()]} disableDate={disableDate}
                                    onCalendarChange={(value) => {
                                        console.log(value);
                                        setDates([value[0], value[1]]);
                                    }}

                                    value={dates}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='newconn-btn-cont'>
                        <Upload customRequest={customRequest}>
                                <Button type='primary' style={{ marginRight: '510px', marginBottom: '280px' }}>Browse</Button>
                        </Upload>
                    </div>

                    <div className='newflatten-btn-cont'>
                        <Button htmlType='submit' type='primary' style={{ marginTop: '220px', marginLeft: '450px' }} onClick={CreateFlatten}>Create Flatten</Button>
                    </div>
                </Form>

            </div>
        </>
    )
}

export default Dflatten