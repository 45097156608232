import React from 'react'
import './css/Error.css'
const InputErrorMessage = (props) => {
    const{required,msg='Please Enter Value',type='default-error-field'}=props
  return (
    <>
    {required&&<div className={`input-field-error-message ${type}`}>{msg}</div>}
    </>
  )
}

export default React.memo(InputErrorMessage)