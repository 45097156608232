import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd'
import { DFlatten,Token} from '../../../api/BackEndURL'

let id = ''
let key = 'updatable'
export const DFlattenGet = createAsyncThunk("DFlattenGet", async () => {
        return await DFlatten.method.get(DFlatten.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})
export const DFlattenPost = createAsyncThunk("DFlattenPost", async (Data) => {
    return await DFlatten.method.post(`${DFlatten.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DFlattenGetOne = createAsyncThunk("DFlattenGetOne", async () => { 
       return await DFlatten.method.get(`${DFlatten.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DFlattenPut = createAsyncThunk("DFlattenPut", async (Data) => {
    return await DFlatten.method.put(`${DFlatten.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};
export const DFlattenReducer = createSlice({
    name: "DFlatten",
    initialState: {
        Data:[],
        dFlattenGetData: [],
        dFlattenAddData: {
            File_name:'',
            File_type:'',
            Description:'',
            start_date:'',
            end_date:'',
            input_path:'',
            output_path:'',
            // is_active:'',
            // server: '', 
            // api_version: '', 
            // personal_access_token_name: '',
            // personal_access_token_secret: '',
            // site_name: '',
            // site_url: '',
            is_active: false,
          
        },
        dFlattenUpdate: {
            File_name:'',
            File_type:'',
            Description:'',
            start_date:'',
            end_date:'',
            input_path:'',
            output_path:'',
            // is_active:'',
            // server: '', 
            // api_version: '', 
            // personal_access_token_name: '',
            // personal_access_token_secret: '',
            // site_name: '',
            // site_url: '',
            is_active: false,
        },
        Fmodal: {
            DflattenEditPop: false,
            DflattenViewPop: false,
            DflattenCreatePop: false,
            Search: true
        },
        loader: {
            Loader: false
        },
        singleData: "",
        getOneData: []
    },
    reducers: {
        DflattenGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DFlattenAddForm: (state, action) => {
            state.Fmodal.Search = action.payload.Search
            state.Fmodal.DFlattenAddForm = action.payload.DFlattenAddForm
        },
        DflattenEditPop: (state, action) => {
            state.Fmodal.DflattenEditPop === false ? state.Fmodal.DflattenEditPop = true : state.Fmodal.DflattenEditPop = false
        },
        DflattenViewPop: (state, action) => {
            state.Fmodal.DflattenViewPop === false ? state.Fmodal.DflattenViewPop = true : state.Fmodal.DflattenViewPop = false
        },
        DflattenCreatePop: (state, action) => {
            state.Fmodal.DflattenCreatePop === false ? state.Fmodal.DflattenCreatePop = true : state.Fmodal.DflattenCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(DFlattenGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(DFlattenGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.dFlattenGetData = action.payload
        }) 
        builder.addCase(DFlattenGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(DFlattenPost.fulfilled, (state, action) => {
            createSuccess(true)
            state.Fmodal.DflattenCreatePop=false;
            state.Fmodal.DflattenAddForm=false;
            state.Fmodal.Search=true;
            state.dFFormAddData.File_name = action.payload.File_name;
            state.dFFormAddData.File_type = action.payload.File_type;
            state.dFFormAddData.Description = action.payload.Description;
            state.dFFormAddData.start_date = action.payload.start_date;
            state.dFFormAddData.end_date = action.payload.end_date;
            state.dFFormAddData.is_active = action.payload.is_active;
            state.Fmodal.DflattenCreatePop = action.payload.DflattenCreatePop;
            // state.dTFormAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(DFlattenPut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.dFFormAddData.File_name = action.payload.File_name;
            state.dFFormAddData.File_type = action.payload.File_type;
            state.dFFormAddData.Description = action.payload.Description;
            state.dFFormAddData.start_date = action.payload.start_date;
            state.dFFormAddData.end_date = action.payload.end_date;
            state.dFFormAddData.is_active = action.payload.is_active;
            state.Fmodal.DflattenEditPop = action.payload.DflattenEditPop
        })
    }
})

// console.log(DFlattenReducer)

export const { DflattenGetId, DFlattenAddForm,dFlattenGet, DflattenEditPop, DflattenViewPop ,DflattenCreatePop} = DFlattenReducer.actions
export default (DFlattenReducer).reducer