import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd'
import { DVisualdt,Token} from '../../../api/BackEndURL'

let id = ''
let key = 'updatable'
export const DVisualdtGet = createAsyncThunk("DVisualdtGet", async () => {
        return await DVisualdt.method.get(DVisualdt.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})
export const DVisualdtPost = createAsyncThunk("DVisualdtPost", async (Data) => {
    return await DVisualdt.method.post(`${DVisualdt.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})
export const DVisualdtGetOne = createAsyncThunk("DVisualdtGetOne", async () => { 
       return await DVisualdt.method.get(`${DVisualdt.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})
export const DVisualdtPut = createAsyncThunk("DVisualdtPut", async (Data) => {
    return await DVisualdt.method.put(`${DVisualdt.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})
export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};
export const DVisualdtReducer = createSlice({
    name: "DVisualdt",
    initialState: {
        DvisualdtData:[],
        DVisualdtAddData: {
            dvisual_config_name: '',
            dvisual_config_detail: '',
            start_date:'',
            end_date: '',
            is_active: false,    
          
        },
        DVisualdtUpdate: {
            dvisual_config_name: '',
            dvisual_config_detail: '',
            start_date:'',
            end_date: '',
            is_active: false,
        },
        Vmodaldt: {
            DVisualdtEditPop: false,
            DVisualdtViewPop: false,
            DVisualdtCreatePop: false,
            Search: true
        },
        loader: {
            Loader: false
        },
        singleData: "",
        getOneData: []
    },
    reducers: {
        DVisualdtGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DVisualdtAddForm: (state, action) => {
            state.Vmodaldt.Search = action.payload.Search
            state.Vmodaldt.DVisualdtAddForm = action.payload.DVisualdtAddForm
        },
        DVisualdtEditPop: (state, action) => {
            state.Vmodaldt.DVisualdtEditPop === false ? state.Vmodaldt.DVisualdtEditPop = true : state.Vmodaldt.DVisualdtEditPop = false
        },
        DVisualdtViewPop: (state, action) => {
            state.Vmodaldt.DVisualdtViewPop === false ? state.Vmodaldt.DVisualdtViewPop = true : state.Vmodaldt.DVisualdtViewPop = false
        },
        DVisualdtCreatePop: (state, action) => {
            state.Vmodaldt.DVisualdtCreatePop === false ? state.Vmodaldt.DVisualdtCreatePop =true : state.Vmodaldt.DVisualdtCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(DVisualdtGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(DVisualdtGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.DvisualdtData = action.payload
        }) 
        builder.addCase(DVisualdtGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(DVisualdtPost.fulfilled, (state, action) => {
            createSuccess(true);
            state.Vmodaldt.DVisualdtCreatePop = false;
            state.Vmodaldt.DVisualdtAddForm = false;
            state.Vmodaldt.Search = true;
            state.DVisualdtAddData.dvisual_config_name = action.payload.dvisual_config_name;
            state.DVisualdtAddData.dvisual_config_detail = action.payload.dvisual_config_detail;
            state.DVisualdtAddData.start_date = action.payload.start_date;
            state.DVisualdtAddData.end_date = action.payload.end_date;
            state.DVisualdtAddData.is_active = action.payload.is_active;
            state.Vmodaldt.DvconfigdtCreatePop = action.payload.DvconfigdtCreatePop;
        });
        
        builder.addCase(DVisualdtPut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.DVisualdtAddData.dvisual_config_name = action.payload.dvisual_config_name;
            state.DVisualdtAddData.dvisual_config_detail = action.payload.dvisual_config_detail;
            state.DVisualdtAddData.start_date = action.payload.start_date;
            state.DVisualdtAddData.end_date = action.payload.end_date;
            state.DVisualdtAddData.is_active = action.payload.is_active;
            state.Vmodaldt.DvconfigdtEditPop = action.payload.DvconfigdtEditPop
        })
    }
})

export const { DVisualdtGetId,DVisualdtAddForm,DVisualdtEditPop,DVisualdtViewPop,DVisualdtCreatePop} = DVisualdtReducer.actions
export default (DVisualdtReducer).reducer