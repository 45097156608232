import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd';
import { PreAudit, Token } from '../../../api/BackEndURL';

let id = ''
let key = 'updatable'
export const preAuditGet = createAsyncThunk("PreAuditGet", async () => {
    return await PreAudit.method.get(PreAudit.URL.get, { headers: { "Authorization": Token } }).then((response) => response.data)
})

export const preAuditPost = createAsyncThunk("PreAuditPost", async (Data) => {
    return await PreAudit.method.post(`${PreAudit.URL.post}`, Data, { headers: { "Authorization": Token } }).then((response) => response.data)
})

export const preAuditGetOne = createAsyncThunk("PreAuditGetOne", async () => {
    return await PreAudit.method.get(`${PreAudit.URL.get}${id}`, { headers: { "Authorization": Token } }).then((response) => response.data)
})

export const preAuditPut = createAsyncThunk("PreAuditPut", async (Data) => {
    return await PreAudit.method.put(`${PreAudit.URL.put}${id}`, Data, { headers: { "Authorization": Token } }).then((response) => response.data)
})

export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};

export const PreAuditReducer = createSlice({
    name: "Audit",
    initialState: {
        PreAuditGetData: [],
        PreAuditAddData: {
            pipeline_name: '',
            Description: '',
            email: '',
            config_id: '',
            Start_date: '',
            End_date: '',
            is_active: false,
            tenant_id: ''
        },
        PreAuditUpdate: {
            pipeline_name: '',
            Description: '',
            email: '',
            End_date: '',
            is_active: true,
        },
        newForm: {
            PreAuditEditPop: false,
            PreAuditViewPop: false,
            PreAuditCreatePop: false,
            Search: true,
            AddForm: false,
            PendingMsg: false,
            SuccessMsg: false,
        },
        loader: {
            Loader: false
        },
        status: '',
        singleData: "",
        getOneData: []
    },
    reducers: {
        PreAuditGetId: (state, action) => {
            state.status = action.payload.status
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        AddForm: (state, action) => {
            state.newForm.Search = action.payload.Search
            state.newForm.AddForm = action.payload.AddForm
        },
        PreAuditEditPop: (state, action) => {
            state.newForm.PreAuditEditPop === false ? state.newForm.PreAuditEditPop = true : state.newForm.PreAuditEditPop = false
        },
        PreAuditViewPop: (state, action) => {
            state.newForm.PreAuditViewPop === false ? state.newForm.PreAuditViewPop = true : state.newForm.PreAuditViewPop = false
        },
        PreAuditCreatePop: (state, action) => {
            state.newForm.PreAuditCreatePop === false ? state.newForm.PreAuditCreatePop = true : state.newForm.PreAuditCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(preAuditGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(preAuditGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.PreAuditGetData = action.payload
        })
        builder.addCase(preAuditGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(preAuditPost.fulfilled, (state, action) => {
            createSuccess(true)
            // state.newForm.PreAuditCreatePop=false;
            // state.newForm.AddForm=false;
            // state.newForm.Search=true;
            // state.PreAuditAddData.pipeline_name = action.payload.pipeline_name;
            // state.PreAuditAddData.Description = action.payload.Description;
            // state.PreAuditAddData.email = action.payload.email;
            // state.PreAuditAddData.configuration_name = action.payload.configuration_name;
            // state.PreAuditAddData.Start_date = action.payload.Start_date;
            // state.PreAuditAddData.End_date = action.payload.End_date;
            // state.PreAuditAddData.is_active = action.payload.is_active;
            state.PreAuditAddData = action.payload;
            state.newForm.PreAuditCreatePop = action.payload.PreAuditCreatePop;
            // state.dTFormAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(preAuditPut.fulfilled, (state, action) => {
            updateSuccess(true)
            // state.PreAuditUpdate.configuration_name = action.payload.configuration_name;
            // state.PreAuditUpdate.pipeline_name = action.payload.pipeline_name;
            // state.PreAuditUpdate.Description = action.payload.Description;
            // state.PreAuditUpdate.config_id = action.payload.config_id;
            // state.PreAuditUpdate.start_date = action.payload.start_date;
            // state.PreAuditUpdate.End_date = action.payload.End_date;
            // state.PreAuditUpdate.is_active = action.payload.is_active;
            state.PreAuditUpdate = action.payload;
            state.newForm.PreAuditEditPop = action.payload.PreAuditEditPop
        })
    }
})

export const { PreAuditEditPop, PreAuditViewPop, PreAuditCreatePop, PreAuditGetId, AddForm } = PreAuditReducer.actions
export default (PreAuditReducer).reducer