import React from 'react'
import '../css/SuccessMessage.css'
import MigrationSuccess from '../../../../../assests/icons/MigrationSuccess.svg'
import {Button} from 'antd'

const SuccessMessage = (props) => {
    const{message,onClick}=props
  return (
    <div className='pre-validation-success-message-aparent-container'>
        <img alt="" src={MigrationSuccess} className='pre-validation-success-message-icon'/>
        <p className='pre-validation-success-message-text'>{message}...</p>
        <div className='pre-validation-success-message-button-container'>
        <Button className='pre-validation-success-message-button' onClick={onClick} type='default'>Done !</Button>
        </div>
    </div>
  )
}

export default SuccessMessage