import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd'
import { DVisual,Token} from '../../../api/BackEndURL'

let id = ''
let key = 'updatable'
export const DVisualGet = createAsyncThunk("DVisualGet", async () => {
        return await DVisual.method.get(DVisual.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})
export const DVisualPost = createAsyncThunk("DVisualPost", async (Data) => {
    return await DVisual.method.post(`${DVisual.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})
export const DVisualGetOne = createAsyncThunk("DVisualGetOne", async () => { 
       return await DVisual.method.get(`${DVisual.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})
export const DVisualPut = createAsyncThunk("DVisualPut", async (Data) => {
    return await DVisual.method.put(`${DVisual.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};
export const DVisualReducer = createSlice({
    name: "DVisual",
    initialState: {
        Data:[],
        dVisualGetData: [],
        dVisualAddData: {
            dvisual_config_name:'',
            connection_details:'',
            start_date:'',
            end_date:'',
            is_active: false,
          
        },
          dVisualUpdate: {
            dvisual_config_name:'',
            connection_details:'',
            start_date:'',
            end_date:'',
            is_active: false,
        },
        Vmodal: {
            DvisualEditPop: false,
            DvisualViewPop: false,
            DvisualCreatePop: false,
            Search: true
        },
        loader: {
            Loader: false
        },
        singleData:"",
        getOneData:[],
    },
    reducers: {
        DvisualGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DVisualAddForm: (state, action) => {
            state.Vmodal.Search = action.payload.Search
            state.Vmodal.DVisualAddForm = action.payload.DVisualAddForm
        },
        DvisualEditPop: (state, action) => {
            state.Vmodal.DvisualEditPop === false ? state.Vmodal.DvisualEditPop = true : state.Vmodal.DvisualEditPop = false
        },
        DvisualViewPop: (state, action) => {
            state.Vmodal.DvisualViewPop === false ? state.Vmodal.DvisualViewPop = true : state.Vmodal.DvisualViewPop = false
        },
        DvisualCreatePop: (state, action) => {
            state.Vmodal.DvisualCreatePop === false ? state.Vmodal.DvisualCreatePop =true : state.Vmodal.DvisualCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(DVisualGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(DVisualGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.dVisualGetData = action.payload
        }) 
        builder.addCase(DVisualGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(DVisualPost.fulfilled, (state, action) => {
            createSuccess(true)
            state.Vmodal.DvisualCreatePop=false;
            state.Vmodal.DvisualAddForm=false;
            state.Vmodal.Search=true;
            state.dVisualAddData.dvisual_config_name = action.payload.dvisual_config_name;
            state.dVisualAddData.connection_details = action.payload.connection_details;
            state.dVisualAddData.start_date = action.payload.start_date;
            state.dVisualAddData.end_date = action.payload.end_date;
            state.dVisualAddData.is_active = action.payload.is_active;
            state.Vmodal.DvisualCreatePop = action.payload.DvisualCreatePop;
            // state.dTFormAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(DVisualPut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.dVisualAddData.dvisual_config_name = action.payload.dvisual_config_name;
            state.dVisualAddData.connection_details = action.payload.connection_details;
            state.dVisualAddData.start_date = action.payload.start_date;
            state.dVisualAddData.end_date = action.payload.end_date;
            state.dVisualUpdate.is_active = action.payload.is_active;
            state.Vmodal.DvisualEditPop = action.payload.DvisualEditPop
        })
    }
})

export const { DvisualGetId, DVisualAddForm,dVisualGet, DvisualEditPop, DvisualViewPop ,DvisualCreatePop} = DVisualReducer.actions
export default (DVisualReducer).reducer