import React, { Fragment, useEffect, useState } from 'react'
import Header from "../../components/header/Header";
import CommonTable from "../../components/table/CommonTable";
import Footer from "../../components/footer/Footer";
import { useSelector, useDispatch } from 'react-redux'
import { dTransformGet, DtranCreatePop, DtranGetId, dTransformGetOne, DtranViewPop, DtranEditPop, DTransAddForm } from '../../redux/reducer/transform/DTransform'
import {DTconfigCreatePop} from '../../redux/reducer/transform/DTransformconfig'
import {DTransformconfigdtGet} from '../../redux/reducer/transform/DTransformconfigdt'
import {DTransformconfigGet} from '../../redux/reducer/transform/DTransformconfig'
import {DTconfigdtCreatePop} from '../../redux/reducer/transform/DTransformconfigdt'
import TransformAdd from '../../components/modal/transform/transformdetail/Add/Transform'
import TransformConfig from '../../components/modal/transform/tconfig/Add/Tconfig'
import TransformConfigdt from '../../components/modal/transform/tconfigdetail/Add/TconfigDetail'
import { Switch, Modal, Drawer, Button, Space } from 'antd';
import * as Fi from 'react-icons/fi';
import * as Fa from 'react-icons/fa';
import moment from 'moment'
function DTransform() {

    const dispatch = useDispatch()
    
    const SearchData = useSelector((state) => state.Header.Search.value)
    const footerPage = useSelector((state) => state.Header.Footer.page)
    const footerPageSize = useSelector((state) => state.Header.Footer.pagesize)
    const DTransformGetAll = useSelector((state) => state.DTransform.dTFormGetData)
    // console.log(DTransformGetAll)
    const DTconfigGetAll =useSelector((state)=> state.DTransformconfig.dTConfigGetData) 
    console.log(DTconfigGetAll)
    
    const DTconfigdtGetAll = useSelector((state)=> state.DTransformconfigdt.dTConfigdtGetData)
    console.log(DTconfigdtGetAll)

    const TransCreate = useSelector((state) => state.DTransform.newForm.DtranCreatePop)
    const TconfigCreate = useSelector((state) => state.DTransformconfig.Tconfig.DTconfigCreatePop)
    const TconfigdtCreate = useSelector((state) => state.DTransformconfigdt.newForm.DTconfigdtCreatePop)

    
    console.log(TransCreate)
    useEffect(() => {
        dispatch(dTransformGet(true))
        dispatch(DTransformconfigGet(true))
        dispatch(DTransformconfigdtGet(true))

    }, [])


    const transformColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '50px',
            render: (text, object, index) => { return index + 1 },
            filteredValue: [SearchData],
            onFilter: (value, record) => {
                return String(record.transform_name).toLowerCase().includes(value.toLowerCase());
            }
        },
        {
            title: 'Table Name',
            dataIndex: 'tName',
            width: '230px',
        },
        {
            title: 'Configuration Name',
            dataIndex: 'config_name',
            // sorter: (a, b) => { return a.config_name.localeCompare(b.config_name) },
            width: '250px',
        },

            
        {
            title: 'Start Date',
            dataIndex: 'sdate',
            // sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
            width: '220px'
        },
        {
            title: 'End Date',
            dataIndex: 'edate',
            // sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
            width: '220px'
        },
        {
            title: 'Active',
            dataIndex: 'active',
            width: '90px'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '70px'
        },
    ];

    const TransformConfigColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '80px',
            // render: (text, object, index) => { return index + 1 },
            render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },
            // filteredValue: [SearchData],
            // onFilter: (value, record) => {
            //     return String(record.dconfig_name).toLowerCase().includes(value.toLowerCase());
            // },
        },
        {
            title: 'Configuration Name',
            dataIndex: 'cname',
            width: '200px',
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        // {
        //     title: 'Description',
        //     dataIndex: 'des',
        //     width: '200px',
        // },
        {
            title: 'Start Date',
            dataIndex: 'sdate',
            // sorter: (a, b) => { return a.sdate.localeCompare(b.sdate) },
            width: '170px'
        },
        {
            title: 'End Date',
            dataIndex: 'edate',
            // sorter: (a, b) => { return a.edate.localeCompare(b.edate) },
            width: '170px'
        },
        {
            title: 'Active',
            dataIndex: 'active',
            width: '130px'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '70px'
        },
    ];

    const TransformConfigdtColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '80px',
            //  render: (text, object, index) => { return index + 1 },
             render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },
           
        },
        {
            title: 'Configuration Detail Name',
            dataIndex: 'configdetails',
            key: 'configdetails',
            // sorter: (a, b) => a.conname.localeCompare(b.conname),
            width: "300px"
        },
        {
            title: 'Configuration Name',
            dataIndex: 'configname',
            key: 'configname',
            sortDirections: ['descend', 'ascend'],
            width: "200px"
        },
    
        {
            title: 'Start Date',
            dataIndex: 'startdate',
            key: 'startdate',
            // sorter: (a, b) => a.startdate.localeCompare(b.startdate),
            sortDirections: ['descend', 'ascend'],
            width: "150px"
        },
        {
            title: 'End Date',
            dataIndex: 'enddate',
            key: 'enddate',
            // sorter: (a, b) => a.enddate.localeCompare(b.enddate),
            sortDirections: ['descend', 'ascend'],
            width: "180px"
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: "100px"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "70px"
        },
    ];
    

    const TransCreateModal = () => {
        dispatch(DTransAddForm({Search:true, DTransAddForm:false}))
        dispatch(DtranCreatePop({DtrancreatePop:false}))
    }

    const Tconfigclose =()=>{
        dispatch(DTconfigCreatePop())
    }

    const Tconfigdtclose =()=>{
        dispatch(DTconfigdtCreatePop())
    }






    const dTconfig = DTconfigGetAll.map((value) => {
        // console.log(value.transform_name)
        return (
            {
                cname: value.connection_name,
                sdate: moment.utc((value.start_date)).format('MM-DD-YYYY'),
                edate: moment.utc((value.end_date)).format('MM-DD-YYYY'),
                active: (<Switch checked={value.is_active === true ? true : false} />),
                action: (
                    <div className='Action_Icons'>
                        {/* {configurationAccess.map((val, key) => { */}
                            {/* return ( */}
                                <Fi.FiEdit size={16} onClick={() => {
                                    // dispatch(DtranGetId({ singleData: value.id }))
                                    // dispatch(DTransformconfigGet())
                                    // dispatch(DtranEditPop())
                                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                            {/* ) */}
                        {/* })} */}
                        <Fa.FaEye size={18} onClick={() => {
                            // dispatch(DtranGetId({ singleData: value.id }))
                            // dispatch(DTransformconfigGet())
                            // dispatch(DtranViewPop())
                        }} style={{ cursor: 'pointer', marginRight: '20px' }} />
                    </div>
                )

            }
        )
    })
    console.log(dTconfig)
  
    const Tconfig = (page, pageSize) => {
        return dTconfig.slice((page - 1) * pageSize, page * pageSize);
    };

//dtcofig


 
const dTconfigdt = DTconfigdtGetAll.map((value) => {
    // console.log(value.transform_name)
    return (
        {
            configdetails: value.dvisual_config_detail,
            startdate: moment.utc((value.start_date)).format('MM-DD-YYYY'),
            enddate: moment.utc((value.end_date)).format('MM-DD-YYYY'),
            active: (<Switch checked={value.is_active === true ? true : false} />),
            action: (
                <div className='Action_Icons'>
                    {/* {configurationAccess.map((val, key) => { */}
                        {/* return ( */}
                            <Fi.FiEdit size={16} onClick={() => {
                                // dispatch(DtranGetId({ singleData: value.id }))
                                // dispatch(DTransformconfigGet())
                                // dispatch(DtranEditPop())
                            }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                        {/* ) */}
                    {/* })} */}
                    <Fa.FaEye size={18} onClick={() => {
                        // dispatch(DtranGetId({ singleData: value.id }))
                        // dispatch(DTransformconfigGet())
                        // dispatch(DtranViewPop())
                    }} style={{ cursor: 'pointer', marginRight: '20px' }} />
                </div>
            )

        }
    )
})
console.log(dTconfig)

const Tconfigdt = (page, pageSize) => {
    return dTconfigdt.slice((page - 1) * pageSize, page * pageSize);
};

 //dtconfigdt





    const dTrans = DTransformGetAll.map((value) => {
        // console.log(value.transform_name)
        return (
            {
                tName : value.transform_name,
                config_name: value.config_name,
                sdate: moment.utc((value.start_date)).format('MM-DD-YYYY'),
                edate: moment.utc((value.end_date)).format('MM-DD-YYYY'),
                active: (<Switch checked={value.is_active === true ? true : false} />),
                action: (
                    <div className='Action_Icons'>
                        {/* {configurationAccess.map((val, key) => { */}
                            {/* return ( */}
                                <Fi.FiEdit size={16} onClick={() => {
                                    dispatch(DtranGetId({ singleData: value.id }))
                                    dispatch(dTransformGetOne())
                                    dispatch(DtranEditPop())
                                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                            {/* ) */}
                        {/* })} */}
                        <Fa.FaEye size={18} onClick={() => {
                            dispatch(DtranGetId({ singleData: value.id }))
                            dispatch(dTransformGetOne())
                            dispatch(DtranViewPop())
                        }} style={{ cursor: 'pointer', marginRight: '20px' }} />
                    </div>
                )

            }
        )
    })
    console.log(dTrans)
    const DataTransform = (page, pageSize) => {
        return dTrans.slice((page - 1) * pageSize, page * pageSize);
    };
    
  
    return (
        <Fragment>
            <Header />
            <CommonTable 
            TransformColumn={transformColumn}
            TransformConfigColumn ={TransformConfigColumn }
            TransformConfigData={Tconfig(footerPage, footerPageSize)}
            TransformConfigdtData={Tconfigdt(footerPage, footerPageSize)}
            TransformConfigdtColumn={TransformConfigdtColumn}
            TransformData={DataTransform(footerPage, footerPageSize)} />
            <Drawer title=" Create D-Transform" open={TransCreate} maskClosable={false} onClose={TransCreateModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={TransCreateModal}>Cancel</Button>
                    </Space>
                }>
                <TransformAdd/>
            </Drawer>

            <Drawer title=" Create Transform-Configuration" open={TconfigCreate}  maskClosable={false}  onClose={Tconfigclose} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={Tconfigclose}>Cancel</Button>
                    </Space>}>
               <TransformConfig/>
            </Drawer>


            
            <Drawer title=" Create Transform-Configuration Detail" open={TconfigdtCreate}  maskClosable={false}  onClose={Tconfigdtclose} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={Tconfigdtclose}>Cancel</Button>
                    </Space>}>
            <TransformConfigdt/>
            </Drawer>

      

            <Footer DataTransform={DTransformGetAll.length} />

        </Fragment>
    )
}

export default DTransform