import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../components/loader/Loader';
import Header from "../../components/header/Header";
import CommonTable from "../../components/table/CommonTable";
import Footer from "../../components/footer/Footer";
import { Switch, Modal, Drawer, Space, Button } from 'antd';
import { preAuditGet, PreAuditCreatePop, AddForm, preAuditGetOne, preAuditPut, PreAuditEditPop, PreAuditViewPop, PreAuditGetId } from '../../redux/reducer/audit/AuditReducer'
import PreAuditAdd from "../../components/modal/audit/preAudit/add/PreAuditAdd"
import PreAuditEdit from "../../components/modal/audit/preAudit/edit/PreAuditEdit"
import PreAuditView from "../../components/modal/audit/preAudit/view/PreAuditView"
import { configurationGet } from '../../redux/reducer/configuration/ConfigurationReducer'
import moment from 'moment';
import * as Fi from 'react-icons/fi'
import * as Fa from 'react-icons/fa'
import * as Ai from 'react-icons/ai';

function Audit() {
    const dispatch = useDispatch()

    const SearchData = useSelector((state) => state.Header.Search.value)
    const footerPage = useSelector((state) => state.Header.Footer.page)
    const footerPageSize = useSelector((state) => state.Header.Footer.pagesize)

    const auditData = useSelector((state) => state.Audit.PreAuditGetData)
    console.log(auditData)
    const AuditAddModalOpen = useSelector((state) => state.Audit.newForm.PreAuditCreatePop)
    const preAuditEditModalOpen = useSelector((state) => state.Audit.newForm.PreAuditEditPop)
    const preAuditViewModalOpen = useSelector((state) => state.Audit.newForm.PreAuditViewPop)
    // const preAuditGetOneData = useSelector((state) => state.Audit.getOneData)
    // console.log(preAuditGetOneData)
    const preAuditPostSuccess=useSelector((state)=>state?.Audit?.PreAuditAddData)
    const preAuditPutSuccess=useSelector((state)=>state?.Audit?.PreAuditUpdate)

    const ConfigurationGetAll = useSelector((state) => state.Configuration.ConfigGetData)


    const auditGetLoader = useSelector((state) => state.Audit.loader.Loader);

    const [play, setPlay] = useState(true)
    const [open, setOpen] = useState("")
    const [error, setError] = useState([])

    useEffect(() => {
        dispatch(preAuditGet(true))
        // dispatch(configurationGet(true))
    }, [preAuditPostSuccess,preAuditPutSuccess])

    const AuditAddModal = () => {
        dispatch(PreAuditCreatePop())
        dispatch(AddForm({ Search: true, AddForm: false }))
    }

    const AuditAddCancel = () => {
        dispatch(AddForm({ Search: true, AddForm: false }))
    }

    const preAuditEditModal = () => {
        // dispatch(preAuditGetOne(true))
        dispatch(PreAuditEditPop())
    }

    const preAuditViewModal = () => {
        // dispatch(pipelineGetOne(true))
        dispatch(PreAuditViewPop())
    }

    const preAuditColumns = [
        {
            key: "1",
            title: 'S.No',
            dataIndex: 'sno',
            width: '50px',
            render: (text, object, index) => { return index + 1 },
            // render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },

        },
        {
            key: "2",
            title: 'PreAudit Name',
            dataIndex: 'name',
            // sorter: (a, b) => { return a.name.localeCompare(b.name) },
            width: '150px',

        },
        {
            key: "3",
            title: 'Configuration Name',
            dataIndex: 'config',
            width: '150px',
        },
        {
            key: "4",
            title: 'Start Date',
            dataIndex: 'stdate',
            // sorter: (a, b) => { return a.stdate.localeCompare(b.stdate) },
            width: '100px'
        },
        {
            key: "5",
            title: 'End Date',
            dataIndex: 'eddate',
            // sorter: (a, b) => { return a.eddate.localeCompare(b.eddate) },
            width: '100px'
        },
        {
            key: "6",
            title: 'Status',
            dataIndex: 'status',
            width: '100px'
        },
        {
            key: "6",
            title: 'Active',
            dataIndex: 'active',
            width: '60px'
        },
        {
            key: "7",
            title: 'Action',
            dataIndex: 'action',
            width: '150px'
        },
    ];

    const [no, setNo] = useState(false)
    const handlepop = (e, id) => {
        // console.log(e.Play==false)
        setOpen(true)
        setNo(id)

        if (e == true) {
            setPlay(false)

        } else {
            setPlay(true)
        }


        const msg = "Are you sure want to play..?"
        setError([...error, msg])
        // window.location.reload()
    }


    const configName = (e) => {
        const configname = []
        ConfigurationGetAll.map((value) => {
            if (e == value.id) {
                configname.push(value.config_name)
            }
        }
        )
        return configname
    }

    const auditFilterData = auditData.filter((val) => {
        if (SearchData === '') {
            return val
        } else if (val.audit_name.toString().toLowerCase().includes(SearchData.toLowerCase())) {
            return val
        }
    })

    // const configFilterData = ConfigurationGetAll.filter((val) => {
    //     if (SearchData === '') {
    //         return val
    //     } else if (val.config_name.toString().toLowerCase().includes(SearchData.toLowerCase())) {
    //         return val
    //     }
    // })

    const dataAudit = auditFilterData.map((val) => {
        return ({
            name: val.pipeline_name,
            des: val.Description,
            config: configName(val.config_id),
            stdate: moment.utc((val.Start_date)).format('MM-DD-YYYY'),
            eddate: moment.utc((val.End_date)).format('MM-DD-YYYY'),
            active: (
                <Switch checked={val.is_active === true ? true : false} />
            ),
            action: (
                <div className='Action_Icons'>
                    {/* {pipelineAccess.map((value, key) => { */}
                    {/* return ( */}
                    <Fi.FiEdit size={16} onClick={() => {
                        dispatch(PreAuditGetId({ singleData: val.id, status: val.is_active }))
                        dispatch(preAuditGetOne())
                        dispatch(preAuditEditModal())
                    }} style={{ cursor: 'pointer', marginRight: '20px' }}
                    />
                    {/* ) */}
                    {/* })} */}
                    <Fa.FaEye size={18}
                        onClick={() => {
                            dispatch(PreAuditGetId({ singleData: val.id }))
                            dispatch(preAuditGetOne())
                            dispatch(preAuditViewModal())
                        }} style={{ cursor: 'pointer', marginRight: '20px' }}
                    />
                    <Ai.AiFillPlayCircle size={19} style={{ cursor: 'pointer', marginRight: '20px' }} />
                    {/* {val.Play == false && <Ai.AiFillPlayCircle size={19} onClick={() => handlepop(val.Play, val.id)}
                        style={{ cursor: 'pointer', marginRight: '20px' }} />}

                    {val.Play == true && <Ai.AiFillPauseCircle size={19}
                        // onClick={() => handlePause(val.Play, val.id)}
                        style={{ cursor: 'pointer', color: '#0c50a3', marginRight: '20px' }} />} */}
                </div>
            )
        })
    })
    // console.log(dataAudit)

    let preAuditData = (page, pageSize) => {
        return dataAudit.slice((page - 1) * pageSize, page * pageSize);
    };

    return (
        <Fragment>
            {auditGetLoader ? (
                <Loader />) : (

                <Fragment>
                    <Header />
                    <CommonTable preAuditColumns={preAuditColumns} preAuditData={preAuditData(footerPage, footerPageSize)} />
                    <Drawer title="New PreAudit" open={AuditAddModalOpen} closable={true} maskClosable={false} onClose={AuditAddModal} ariaHideApp={false} width='75vh'
                        footer={[
                            <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                            </div>]}
                        footerStyle={{ backgroundColor: '#f5f5fd' }}
                        extra={
                            <Space onClick={AuditAddCancel}>
                                <Button onClick={AuditAddModal}>Cancel</Button>
                                {/* <Cg.CgClose size={20}  onClick={pipelineAddModal} style={{cursor:"pointer",color:"red"}}/> */}
                            </Space>
                        }
                    >
                        <PreAuditAdd />
                    </Drawer>

                    <Drawer title="PreAudit Edit" open={preAuditEditModalOpen} closable={true} maskClosable={false} onClose={preAuditEditModal} ariaHideApp={false} width='75vh'
                        footer={[
                            <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                            </div>]}
                        footerStyle={{ backgroundColor: '#f5f5fd' }}
                    // extra={
                    //     <Space onClick={AuditEditCancel}>
                    //     <Button onClick={preAuditEditModal}>Cancel</Button>
                    //         {/* <Cg.CgClose size={20}  onClick={preAuditEditModal} style={{cursor:"pointer",color:"red"}}/> */}
                    //     </Space>
                    // }
                    >
                        <PreAuditEdit />
                    </Drawer>

                    <Drawer title="Pipeline View" open={preAuditViewModalOpen} closable={true} maskClosable={false} onClose={preAuditViewModal} ariaHideApp={false} width='75vh' footer={null}
                    // extra={
                    //     <Space>
                    //         <Button onClick={preAuditViewModal}>Cancel</Button>
                    //         {/* <Cg.CgClose size={20}  onClick={preAuditViewModal} style={{cursor:"pointer",color:"red"}}/> */}
                    //     </Space>
                    // }
                    >
                        <PreAuditView />
                    </Drawer>

                    <Footer preAuditdata={auditData.length} />
                </Fragment>
            )}
        </Fragment>
    )
}

export default Audit
