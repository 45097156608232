import React,{useState} from 'react'
import './css/Validation.css'
import PostValidationHeader from '../../components/commmon/components/postValidation/js/Header'
import TableList from '../../components/commmon/components/postValidation/js/TableList'
import { Row,Col } from 'antd'
import {sampleData} from './mockdata'
import ReactHtmlParser from 'html-react-parser';

const Validation = () => {

    const [customHtml,setCustomHtml]=useState('../../assests/Customers.html')
    

  let validationData=[
    {
        "table_name": "postaudit",
        "source_row_count": 13299,
        "target_row_count": 13299,
        "hashing": true,
        "inset": [
            "ID",
            "NAME",
            "SALARY",
            "DEPARTMENT",
            "HIRE_DATE"
        ],
        "notinset": [],
        "source_profiling": [
            {
                "ID": {
                    "type": "Numeric",
                    "memory_size": 106524,
                    "mean": 6650.0,
                    "std": 3839.2349498304998,
                    "variance": 14739725.0,
                    "kurtosis": -1.2,
                    "skewness": 0.0,
                    "chi_squared": {
                        "statistic": 0.004737198285585383,
                        "pvalue": 1.0
                    }
                },

                "NAME": {
                    "type": "Text",
                    "memory_size": 106524,
                    "length_histogram": {
                        "11": 9000,
                        "12": 3300,
                        "10": 900,
                        "9": 90,
                        "8": 9
                    },
                    "histogram_length": {
                        "counts": [
                            9,
                            90,
                            900,
                            9000,
                            3300
                        ],
                        "bin_edges": [
                            8.0,
                            8.8,
                            9.6,
                            10.4,
                            11.2,
                            12.0
                        ]
                    },
                    "mean_length": 11.16489961651252,
                    "median_length": 11
                },
                "SALARY": {
                    "type": "Numeric",
                    "memory_size": 106524,
                    "mean": 70079.86925464655,
                    "std": 17223.26580112979,
                    "variance": 296640884.85636693,
                    "kurtosis": -1.1821388341389625,
                    "skewness": 0.0006837308632070964,
                    "chi_squared": {
                        "statistic": 28.812241521918942,
                        "pvalue": 0.2272772777743121
                    }
          
                },
                "HIRE_DATE": {
                    "type": "DateTime",
                    "memory_size": 106524,
                    "range": "13298 days, 0:00:00"
                }
            },
        ],
        "target_profiling": [
            {
                "ID": {
                    "type": "Numeric",
                    "memory_size": 106524,
                    "mean": 6650.0,
                    "std": 3839.2349498304998,
                    "variance": 14739725.0,
                    "kurtosis": -1.2,
                    "skewness": 0.0,
                    "chi_squared": {
                        "statistic": 0.004737198285585383,
                        "pvalue": 1.0
                    }
                },
                "NAME": {
                    "type": "Text",
                    "memory_size": 106524,
                    "length_histogram": {
                        "11": 9000,
                        "12": 3300,
                        "10": 900,
                        "9": 90,
                        "8": 9
                    },
                    "histogram_length": {
                        "counts": [
                            9,
                            90,
                            900,
                            9000,
                            3300
                        ],
                        "bin_edges": [
                            8.0,
                            8.8,
                            9.6,
                            10.4,
                            11.2,
                            12.0
                        ]
                    },
                    "mean_length": 11.16489961651252,
                    "median_length": 11
                },
                "SALARY": {
                    "type": "Numeric",
                    "memory_size": 106524,
                    "mean": 70079.86925464655,
                    "std": 17223.26580112979,
                    "variance": 296640884.85636693,
                    "kurtosis": -1.1821388341389625,
                    "skewness": 0.0006837308632070964,
                    "chi_squared": {
                        "statistic": 28.812241521918942,
                        "pvalue": 0.2272772777743121
                    }
                },
                "HIRE_DATE": {
                    "type": "DateTime",
                    "memory_size": 106524,
                    "range": "13298 days, 0:00:00"
                }
            }
        ]
    }
]

const tableArr=["source_profiling","target_profiling"]
const tableTitle={
  "source_profiling":'SOURCE',
  "target_profiling":'TARGET'
}
  return (
    <div style={{border:'1px solid red',boxSizing:'border-box'}}>
        {/* <PostValidationHeader/> */}
        <div style={{height:'50vh',width:'100%',overflow:'auto',boxSizing:'border-box',border:'1px solid red'}}>
    {/* <div dangerouslySetInnerHTML={{__html:sampleData?.target_html_file}}/> */}
    <div style={{border:'1px solid red'}}>{ReactHtmlParser(sampleData?.target_html_file)}</div>
    </div>
        {/* {validationData&&validationData?.map((value,key)=>{
          return(
            <div className='validation-table-list-container' key={key}>
            <Row gutter={[16,16]}>
              {tableArr&&tableArr?.map((table,index)=>{
                return(
                  <Col lg={{offset:0,span:12}} key={key}>
                  <TableList title={tableTitle?.[table]} tableType={table} varient={value} data={value?.[table]} key={index}/>
              </Col>
                )
              })}
            </Row>
    </div>
          )
        })} */}
    </div>
  )
}

export default Validation