import React, { useEffect, useMemo, useState } from 'react'
import './PipelineDetailAdd.css'
import { Row, Button, Form, Switch, Input, Col, DatePicker, List, AutoComplete,Modal,Space, Tag  } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { PipelinedetailPost, PplDtlAddForm } from '../../../../../redux/reducer/pipeline/PipelineDetailReducer';
import Item from 'antd/lib/list/Item';
import axios from 'axios';
import columnListingTable from '../../../../commmon/components/columnListingTable';
import ColumnListingTable from '../../../../commmon/components/columnListingTable';
import {URL} from '../../../../../api/BackEndURL'


function PipelineDetailAdd() {

  const dispatch = useDispatch()
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';

  const [pipelineDetails, setPipelineDetails] = useState({
    pipelineName: '',
    pipelineId: '',
    pipelineDetailsName: '',
    targetName: '',
    schemaName:'',
    sourceName: '',
    startDate: '',
    endDate: '',
    sqlExtractName: '',
    sqlExtractId: '',
    status: false,
  })

  const [schemaAndSourceListData,setSchemaAndSourceListData]=useState([])

  const handleChange = (e) => {
    setPipelineDetails({ ...pipelineDetails, [e.target.name]: e.target.value })
  }

  const [extractstatus, setExtractStatus] = useState(false)
  const [pipeDtlSerach, setpipeDtlSerach] = useState(true)
  const [createForm, setCreateForm] = useState(false);
  const [formList, setFormList] = useState(false);
  const [sqlFormList, setSqlFormList] = useState(false)

  const [formSearch, setFormSerach] = useState('');
  const [sqlFormSearch, setSqlFormSearch] = useState('')

  const [sqlSearchInput, setSqlSearchInput] = useState(true)
  const [sqlNameDisplay, setSqlNameDisplay] = useState(false)

  // const [columnSelectModal,setColumnSelectModal]=useState(false)
  // const [selectedColumnList,setSelectedColumnList]=useState([])
  const[sourceColumnList,setSourceColumnList]=useState([])


  const pipelineData = useSelector((state) => state.Pipeline.Data)
  const sqlExtractData = useSelector((state) => state.SqlExtract.getData)
  const addform = useSelector((state) => state.PipelineDetail.newForm.PplDtlAddForm)
  const Search = useSelector((state) => state.PipelineDetail.newForm.Search)

  const handleSearch = (e) => {
    setFormSerach(e.target.value)
    e.target.value.length > 0 ? setFormList(true) : setFormList(false)
  }

  const sqlExtractHandleChange = (e) => {
    setSqlFormSearch(e.target.value)
    e.target.value.length > 0 ? setSqlFormList(true) : setSqlFormList(false)
  }

  const sqlNameDisplayHandleChange = (e) => {
    // setSqlExtractName('')
    setPipelineDetails({ ...pipelineDetails, sqlExtractName: '', sqlExtractId: '' })
    setSqlNameDisplay(false);
    setSqlSearchInput(true);
  }

  const handleSelect = (e, name) => {
    if(name?.toLowerCase()==='schemaname'){
    setPipelineDetails({...pipelineDetails,["sourceName"]:''})
    }
    setPipelineDetails({ ...pipelineDetails, [name]: e })
  }

  const filteredData = pipelineData.filter((val) => {
    if (val.is_active === true) {
      return val
    }
  })

  const sqlFilterData = sqlExtractData.filter((val) => {
    if (val.is_active === true) {
      return val
    }
  })

  const data = filteredData.filter((val) => {
    console.log(val.pipeline_name)
    if (formSearch === "") {
      return val;

    } else if (val.pipeline_name.toString().toLowerCase().includes(formSearch.toLowerCase())) {
      return val
    }
  }).map((val) => {
    return {
      id: val.id,
      pipeline_name: val.pipeline_name,
      status: val.is_active,
    }
  })

  const sqlData = sqlFilterData.filter((val) => {
    if (sqlFormSearch === '') {
      return val;
    } else if (val.database_name.toString().toLowerCase().includes(sqlFormSearch.toLocaleLowerCase())) {
      return val
    }
  }).map((val) => {
    return {
      id: val.id,
      tittle: val.database_name,
      status: val.is_active
    }
  })



  const statusSwitch = (checked) => {
    setPipelineDetails({ ...pipelineDetails, status: checked })
  };
  const sqlExtractSwitch = (checked) => { setExtractStatus(checked) }
  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current < moment().startOf('day')
  // };
  const datePickerOnChange = (dates, dateStrings) => {
    setPipelineDetails({ ...pipelineDetails, startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  const addForm = () => {
    dispatch(PipelinedetailPost({
      pipeline_id: pipelineDetails.pipelineId,
      pipeline_name: pipelineDetails.pipelineName,
      sql_extract_id: pipelineDetails.sqlExtractId,
      sql_extract_name: pipelineDetails.sqlExtractName,
      pipeline_detail_name: pipelineDetails.pipelineDetailsName,
      target_table_name: pipelineDetails.targetName,
      source_table_name: pipelineDetails.sourceName,
      selectedColumns:sourceColumnList,
      start_date: pipelineDetails.startDate,
      end_date: pipelineDetails.endDate,
      is_active: pipelineDetails.status,
      schemaName:pipelineDetails?.schemaName,
      tenant_id: JSON.parse(sessionStorage.getItem("id")),
    }))

  }
const getSchemaAndSource=(id)=>{
axios.get(`${URL}/source_table_select/${id}`)?.then((res)=>{
  setSchemaAndSourceListData(res?.data)
})
}

const getColumnList=(sourceName)=>{
  axios?.post(`${URL}/columlist/`,{
    pipeline_id: pipelineDetails.pipelineId,
    target_table_name: sourceName,
    schemaName:pipelineDetails?.schemaName,       
  })?.then((res)=>{
    setSourceColumnList(res?.data?.table_columns?.COLUMN_NAME)
    console.log('pipelineDetailsColumnList-SUCCESS',res)
  })
}

const schemaList=useMemo(()=>{
  return schemaAndSourceListData?.map((schema)=>{
    return{value:schema?.schema_names,label:schema?.schema_names}
  })
},[schemaAndSourceListData])


const sourceTableList=useMemo(()=>{
  let target=[]
  schemaAndSourceListData?.forEach((source)=>{
    if(source?.schema_names?.toLowerCase()===pipelineDetails?.schemaName?.toLowerCase()){
      target=source?.table_names
    }
  })

 return target?.map((table)=>{
    return{value:table,label:table}
  })
},[pipelineDetails?.schemaName])

const getEndDate=useMemo(()=>{

  let endDate=""
  let startDate=""

  pipelineData&&pipelineData?.forEach((value)=>{
    if(pipelineDetails?.pipelineName==value?.pipeline_name){
      endDate=value?.End_date
      startDate=value?.Start_date
    }
  })
return {endDate:endDate,startDate:startDate}
},[pipelineDetails?.pipelineName])

const disabledDate = (current) => {
  // Set your maximum date
  // const currentDate = moment(getEndDate?.startDate,'YYYY-MM-DD');

  let currentDate=moment.max(moment(getEndDate?.startDate,'YYYY-MM-DD'), moment());

  let maxAllowedDate=moment(getEndDate?.endDate, 'YYYY-MM-DD');

  return current && (current < currentDate.startOf('day') || current > maxAllowedDate.endOf('day'));
};

  return (

    <div className='piplineDetailCreate_overall'>

      {/* <Modal title={false} style={{ top: 25 }} open={columnSelectModal} maskClosable={false} closable={false} ariaHideApp={false} footer={null}>
              <ColumnListingTable columnList={sourceColumnList} modal={setColumnSelectModal} setState={setSelectedColumnList}/>
        </Modal> */}

      {Search && <div className='PipelineDetail_Search_Parent_Container'>

        <Input.Search className='PipelineDetail_Add_Search_Input' onChange={handleSearch} placeholder='Search Pipeline Names' enterButton />

        {formList && <div className='PipelineDetail_Add_Search_Sugg_Box'>

          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item onClick={() => {
                // setPipeline_name(item.pipeline_name)
                getSchemaAndSource(item?.id)
                setPipelineDetails({ ...pipelineDetails, pipelineId: item.id, pipelineName: item.pipeline_name })
                // setPipelineId(item.id)
                // setpipeDtlSerach(false)
                // setCreateForm(true)
                setFormList(false)
                dispatch(PplDtlAddForm({ Search: false, PplDtlAddForm: true }))
              }} className='PipelineDetail_Search_List' style={{ height: "60px", cursor: 'pointer', userSelect: 'none', textTransform: "capitalize", fontFamily: "Nunito" }}>
                <List.Item.Meta
                  title={<p className='PipelineDetail_Search_List_Title_text'>{item.pipeline_name}</p>}
                  description={<p className={item.status === true ? "Status_Active_Text" : "Status_InActive_Text"}>{item.status === true ? "Active" : "In_Active"}</p>}
                />
              </List.Item>
            )}
          />

        </div>}

      </div>}

      {addform && <Form name="basic" autoComplete="off" layout='vertical' size='medium' style={{ fontFamily: "Nunito" }}>
        <Row gutter={[0, 0]} >

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 11, offset: 0 }}>
            <Form.Item
              style={{ width: "100%" }}
              label="Pipeline Name"
              name="pipeline name"
              rules={[
                {

                },
              ]}
            >
              <Input style={{ textTransform: "capitalize" }} className="Pipeline-Det-Input" defaultValue={pipelineDetails.pipelineName} disabled />
            </Form.Item>
          </Col>

          <Col lg={{ span: 11, offset: 2 }}>
            <Form.Item
              label="Pipeline Detail Name"
              name=" Pipeline Detail Name"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Pipeline Detail Name',
                },
              ]}
            >
              <Input style={{ textTransform: "capitalize" }} className="Pipeline-Det-Input" name='pipelineDetailsName' onChange={handleChange} />
            </Form.Item>
          </Col>

          <Col lg={{ span: 24, offset: 0 }}>
            <Form.Item
              label="Schema Name"
              name="schemaName"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Target table Name',
                },
              ]}
            >
              {/* <Input style={{textTransform:"capitalize"}}  className="Pipeline-Det-Input" name='targetName' onChange={handleChange} /> */}
              <AutoComplete
                style={{ width: '100%', textTransform: "capitalize" }}
                className='srcSchema-inputfeild'
                options={schemaList}
                name="schemaName"
                onChange={(e)=>{if(e?.length<=0){
                  setPipelineDetails({...pipelineDetails,["sourceName"]:'',["schemaName"]:''})
                }}}
                onSelect={(e) => handleSelect(e, "schemaName")}
                filterOption={(inputValue, type) =>
                  type.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 11, offset: 0 }}>
            <Form.Item
              label="Source Table Name "
              name="sourceName"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Source table name',
                },
              ]}
            >
              {/* <Input style={{textTransform:"capitalize"}}  className="Pipeline-Det-Input" name='sourceName' onChange={handleChange} /> */}
              <AutoComplete
                key={pipelineDetails?.sourceName}
                value={pipelineDetails?.sourceName}
                disabled={pipelineDetails?.schemaName&&pipelineDetails.schemaName?.length>0?false:true}
                style={{ width: '100%', textTransform: "capitalize" }}
                className='srcSchema-inputfeild'
                options={sourceTableList}
                onChange={(e) =>{if(e?.length<=0){handleSelect("", "sourceName")}}}
                onSelect={(e) =>{
                  getColumnList(e)
                  handleSelect(e, "sourceName")
                  // setColumnSelectModal(true)
                }}
                // filterOption={(inputValue, type) =>
                //   type.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                // }

              />
            </Form.Item>
          </Col>

          <Col lg={{ span: 11, offset: 2 }}>
            <Form.Item
              label="Target Table Name"
              name="targetName"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Target table Name',
                },
              ]}
            >
              <Input style={{ textTransform: "capitalize" }} className="Pipeline-Det-Input" name='targetName' onChange={handleChange} />
              {/* <AutoComplete
                  style={{ width: '100%', textTransform: "capitalize" }}
                  className='srcSchema-inputfeild'
                  options={[{value:'hello',label:'hello'}]}
                /> */}
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
            <Form.Item
            key={selectedColumnList}
              label="Selected Columns"
              name="selectedColumns"
              rules={[
                {
                  required:true,
                  message:selectedColumnList?.length>0?'':'Please select the Columns'
                },
              ]}
            >
             {selectedColumnList?.length>0 ? <Space size={[0, 8]} wrap>
                {selectedColumnList&&selectedColumnList?.map((value,key)=>{
                 return <Tag className='pipeline-details-selected-column-tags' key={key}>{value}</Tag>
                })}
              </Space>
                 :
               <p>Theren is No Column Selected !</p>}
            </Form.Item>
          </Col> */}

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
            <Form.Item
              label="Start Date to End Date"
              name="StartDateToEndDate"
              rules={[
                {
                  required: true,
                  message: 'Please Select End Date',
                },
              ]}
            >
              <RangePicker disabledDate={disabledDate} defaultValue={[moment()]} onChange={datePickerOnChange} dateFormat style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 0 }}>
            <Form.Item
              label="Bussiness Logic"
              name="extract sql name"
            >
              <Switch defaultChecked={false} onChange={sqlExtractSwitch} />
            </Form.Item>
          </Col> */}

          {extractstatus && <Col lg={{ span: 11, offset: 8 }}>
            <Form.Item
              key={pipelineDetails.sqlExtractName}
              label="Bussiness Logic Query"
              name="sqlExtractName">
              {sqlSearchInput && <Input.TextArea style={{ textTransform: "capitalize" }} onChange={sqlExtractHandleChange} />}
              {sqlNameDisplay && <Input.TextArea style={{ textTransform: "capitalize" }} defaultValue={pipelineDetails.sqlExtractName} name="sqlExtractName" onChange={sqlNameDisplayHandleChange} />}
            </Form.Item>
            {sqlFormList && <div className='SqlExtract-Form-List'>
              <List
                itemLayout="horizontal"
                dataSource={sqlData}
                renderItem={item => (
                  <List.Item onClick={() => {
                    setPipelineDetails({ ...pipelineDetails, sqlExtractName: item.tittle, sqlExtractId: item.id })
                    // setSqlExtractId(item.id)
                    setSqlFormList(false)
                    setSqlSearchInput(false)
                    setSqlNameDisplay(true)
                  }} className='PipelineDetail_Search_List' style={{ height: "60px", cursor: 'pointer', userSelect: 'none', textTransform: "capitalize" }}>
                    <List.Item.Meta
                      title={<p className='PipelineDetail_Search_List_Title_text'>{item.tittle}</p>}
                      description={<p className={item.status === true ? "Status_Active_Text" : "Status_InActive_Text"}>{item.status === true ? "Active" : "In_Active"}</p>}
                    />
                  </List.Item>
                )}
              />
            </div>}
          </Col>}

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
            <Form.Item
              label="Status"
              name="status"
            >
              <Switch name="status" onChange={statusSwitch} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 10, offset: 0 }}>
            <Button type="primary" onClick={() => {
              // setpipeDtlSerach(true)
              // setCreateForm(false)
              dispatch(PplDtlAddForm({ Search: true, PplDtlAddForm: false }))
              setFormSerach('')
              setFormList(false)
            }} className='PipelineDetail_Back_Button'>Back</Button>
          </Col>
          <div style={{ display: "flex", width: "97%", justifyContent: "flex-end" }}>
            {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 10, offset: 4 }}> */}
            <Button type="primary" onClick={addForm} htmlType="submit" className='PipelineDetial_Add_Button'> Create </Button>
            {/* </Col> */}
          </div>
        </Row>
      </Form>}
    </div>
  )

}

export default PipelineDetailAdd