import React,{useEffect, useLayoutEffect, useMemo, useState} from 'react'
import './css/ColumnListingTable.css'
import { Input,Checkbox,Button} from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { URL } from '../../../../api/BackEndURL';
import axios from 'axios';
import { PipelinedetailPut } from '../../../../redux/reducer/pipeline/PipelineDetailReducer';
import Spinner from '../../atoms/spinner';

const ColumnListingTable = (props) => {
    const{modal}=props
    const pipeDetailGetOneData = useSelector((state) => state.PipelineDetail.getOneData)
    const { Search } = Input;
    const dispatch=useDispatch()

    const [searchValue,setSearchValue]=useState("")
    const [totalColumnList,setTotalColumnList]=useState([])
    // const [columnList,setColumnList]=useState([])

    const getPipelineDetailData=useMemo(()=>{
        let obj={}
        pipeDetailGetOneData&&pipeDetailGetOneData?.forEach((value)=>{
            // setColumnList([...value?.selectedColumns])
            obj={...value}
        })
        return obj
    },[pipeDetailGetOneData])

    useEffect(()=>{
        axios?.post(`${URL}/columlist/`,{
          pipeline_id: getPipelineDetailData?.pipeline_id,
          target_table_name: getPipelineDetailData?.source_table_name,
          schemaName:getPipelineDetailData?.schemaName,
        })?.then((res)=>{
          setTotalColumnList(res?.data?.table_columns?.COLUMN_NAME)
     })
      },[getPipelineDetailData])

    let columnList=getPipelineDetailData?.selectedColumns||[]

    const filteredColumnList=useMemo(()=>{
       return totalColumnList && totalColumnList?.filter((value)=>{
            if(searchValue==""){
                return value
            }else if(value?.toLowerCase()?.includes(searchValue?.toLowerCase())){
                return value
            }
        })
    },[totalColumnList,searchValue])

    const [selectedColumn,setSelectedColumn]=useState(columnList)

    useEffect(()=>{
        setSelectedColumn(columnList)
    },[columnList])

      const checkBoxChange=(e,name)=>{
        if(name?.toLowerCase()==="all"&&e?.target?.checked===true){
            setSelectedColumn(totalColumnList)
            searchValue("")
        }else if(name?.toLowerCase()==="all"&&e?.target?.checked===false){
            setSelectedColumn([])
        }else if(e?.target?.checked===true && !selectedColumn?.includes(name)){
            setSelectedColumn([...selectedColumn,name])
        }else if(e?.target?.checked===false){
            let removeColumn=selectedColumn?.filter((column)=>{
                if(column!==name&&column!=='ALL')return column})
            setSelectedColumn(removeColumn)
        }
      }

const UpdateTheSelectedColumnList=()=>{
        dispatch(PipelinedetailPut({
            "pipeline_detail_name": getPipelineDetailData?.pipeline_detail_name,
            "target_table_name": getPipelineDetailData?.target_table_name,
            "source_table_name": getPipelineDetailData?.source_table_name,
            "end_date": getPipelineDetailData?.end_date,
            "is_active": getPipelineDetailData?.is_active,
            "selectedColumns":selectedColumn
        }))
}

const closeModal=()=>{
    setSearchValue("")
        setSelectedColumn([])
    modal(false)
    setTotalColumnList([])
}

let checkAll=useMemo(()=>{
return totalColumnList?.length===selectedColumn?.length
},[totalColumnList,selectedColumn])

console.log('qqq-1',totalColumnList?.length===selectedColumn?.length)

  return (
    <div className='column-listing-table-parent-container' key={modal}>
        <p className='column-listing-table-title'>{getPipelineDetailData?.source_table_name} Column List</p>
        <Search value={searchValue} onChange={(e)=>setSearchValue(e?.target?.value)} className='column-listing-table-search' size='large' placeholder="input search text" enterButton />

<div className='column-listing-table-container'>
        <table >
            <tr className='column-listing-table-tr'>
                <th className='column-listing-table-th'> <Checkbox key={checkAll||searchValue||selectedColumn||totalColumnList} defaultChecked={totalColumnList?.length===selectedColumn?.length?true:false} onChange={(e)=>checkBoxChange(e,'ALL')}> ALL </Checkbox></th>
                <th className='column-listing-table-th column-listing-table-width-100'>Column Name</th>
            </tr>
            <Spinner size={'lg'} data={filteredColumnList}/>
            {filteredColumnList&& filteredColumnList?.map((column,key)=>{
                return(
                    <tr className='column-listing-table-tr' key={key}>
                    <td className='column-listing-table-td'><Checkbox onChange={(e)=>checkBoxChange(e,column)} key={checkAll||searchValue||selectedColumn||totalColumnList} defaultChecked={(selectedColumn?.includes(column))?true:false}></Checkbox></td>
                    <td className='column-listing-table-td'>{column}</td>
                </tr>
                )
            })}
            
        </table>
        </div>
        <div className='column-listing-table-button-container'>
        <Button onClick={closeModal} className='column-listing-table-buttons cancelButton' >Cancel</Button>
        <Button onClick={UpdateTheSelectedColumnList} className='column-listing-table-buttons' type='primary'>Select</Button>
        </div>
    </div>
  )
}

export default React.memo(ColumnListingTable)