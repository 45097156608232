import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd'
import { DVisualreport,Token} from '../../../api/BackEndURL'




let id = ''
let key = 'updatable'
export const DVisualreportGet = createAsyncThunk("DVisualreportGet", async () => {
        return await DVisualreport.method.get(DVisualreport.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})

export const DVisualreportPost = createAsyncThunk("DVisualreportPost", async (Data) => {
    return await DVisualreport.method.post(`${DVisualreport.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data.file_url)
})

export const DVisualreportGetOne = createAsyncThunk("DVisualreportGetOne", async () => { 
       return await DVisualreport.method.get(`${DVisualreport.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const DVisualreportPut = createAsyncThunk("DVisualreportPut", async (Data) => {
    return await DVisualreport.method.put(`${DVisualreport.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})




export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};
export const DVisualreportReducer = createSlice({
    name: "DVisualreport",
    initialState: {
        DVisualreportGetData: [],
        DVisualreportAddData: {
            dvisual_report_name: '', 
            start_date: '', 
            end_date: '',
            workbook_name: '',
            dvisual_config_detail_id:'',
        },
        DVisualreportUpdate: {
            dvisual_report_name: '', 
            start_date: '', 
            end_date: '',
            workbook_name: '',
            dvisual_config_detail_id:''
           
        },
        Vreport: {
            DVisualreportEditPop: false,
            DVisualreportViewPop: false,
            DVisualreportCreatePop: false,
            DVisualreportAddForm: false,
            Search: true
        },
        loader: {
            Loader: false
        },
        singleData: "",
        getOneData: []
    },
    reducers: {
        DVisualreportGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DVisualreportAddForm: (state, action) => {
            state.Vreport.Search = action.payload.Search
            state.Vreport.DVisualreportAddForm = action.payload.DVisualreportAddForm
        },
        DVisualreportEditPop: (state, action) => {
            state.Vreport.DVisualreportEditPop === false ? state.Vreport.DVisualreportEditPop = true : state.Vreport.DVisualreportEditPop = false
        },
        DVisualreportViewPop: (state, action) => {
            state.Vreport.DVisualreportViewPop === false ? state.Vreport.DVisualreportViewPop = true : state.Vreport.DVisualreportViewPop = false
        },
        DVisualreportCreatePop: (state, action) => {
            state.Vreport.DVisualreportCreatePop === false ? state.Vreport.DVisualreportCreatePop =true : state.Vreport.DVisualreportCreatePop = false
            
        }
    },
    extraReducers: (builder) => {
        builder.addCase(DVisualreportGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(DVisualreportGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.DVisualreportGetData = action.payload
        }) 
        builder.addCase(DVisualreportGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(DVisualreportPost.fulfilled, (state, action) => {
            const file_url = action.payload;
            console.log("post",file_url)
            createSuccess(true)
            state.Vreport.DVisualreportCreatePop=false;
            state.Vreport.DVisualreportAddForm=false;
            state.Vreport.Search=true;
            state.DVisualreportAddData.dvisual_report_name = action.payload.dvisual_report_name;
            state.DVisualreportAddData.start_date = action.payload.start_date;
            state.DVisualreportAddData.end_date = action.payload.end_date;
            state.DVisualreportAddData.workbook_name = action.payload.workbook_name;
            state.DVisualreportAddData.dvisual_config_detail_id = action.payload.dvisual_config_detail_id;
            // state.DVisualreportAddData.action = action.payload.action;
            state.Vreport.DtranCreatePop = action.payload.DtranCreatePop;
            state.DVisualreportAddData.file_url = file_url;
            // state.DVisualreportAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(DVisualreportPut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.DVisualreportAddData.dvisual_report_name = action.payload.dvisual_report_name;
            state.DVisualreportAddData.start_date = action.payload.start_date;
            state.DVisualreportAddData.end_date = action.payload.end_date;
            state.DVisualreportAddData.workbook_name = action.payload.workbook_name;
            state.DVisualreportAddData.dvisual_config_detail_id = action.payload.dvisual_config_detail_id;
            // state.DVisualreportAddData.action = action.payload.action;
            state.Vreport.DtranEditPop = action.payload.DtranEditPop
        })
    }
})

export const { DVisualreportGetId,DVisualreportAddForm,DVisualreportEditPop,DVisualreportViewPop,DVisualreportCreatePop} = DVisualreportReducer.actions
export default (DVisualreportReducer).reducer