import React,{useState,useCallback, useEffect} from 'react'
import './css/MultipleTableColumnList.css'
import { Button, Checkbox } from 'antd'
import { useSelector,useDispatch } from 'react-redux'
import axios from 'axios'
import { URL } from '../../../../api/BackEndURL'
import { SchemaMigratePut } from '../../../../redux/reducer/pipeline/SchemamigReducer'
import Spinner from '../../atoms/spinner'

const MultipleTableColumnList = (props) => {
    const{setModal}=props
    const dispatch=useDispatch()
    const schemaDataById = useSelector((state) => state.SchemaMigration?.getOneData)
    const [tableColumnList,setTableColumnList]=useState([])
    const [listOpen,setListOpen]=useState({})
    const [selectedTableColumns,setSelectedTableColumns]=useState(schemaDataById?.table_columns)

    useEffect(()=>{
        if(Object?.values(schemaDataById)?.length>0){
            axios?.post(`${URL}/select_source_schema/`,{
              "id":schemaDataById?.config_id,
              "schema_name":schemaDataById?.source_schema_name,
              "table_name":schemaDataById?.source_table_name,
              "all_table":false
              }).then((res)=>{
                setTableColumnList(res?.data?.at(0)?.table_column)
              }).catch((err)=>{
                console.error('schema-column-error',err)
              })
            }
    },[schemaDataById])

    useEffect(()=>{
            setSelectedTableColumns(schemaDataById?.table_columns)
    },[schemaDataById])

    const listOpenAndClose=(name)=>{
        // setListOpen({...listOpen,[name]:listOpen?.[name]?false:true})
        setListOpen({[name]:listOpen?.[name]?false:true})
    }

    const getSelectedColumnValueUsingTableName=useCallback((tableName)=>{
        let arr=[]
        selectedTableColumns&&selectedTableColumns?.filter((table)=>{
            if(table?.table_name?.toLowerCase()===tableName?.toLowerCase()){
                arr=table?.column
            }
        })
        return arr
    },[selectedTableColumns,tableColumnList])

    const tableNameExist=(tableName)=>{
        let tableNameExists=false
        selectedTableColumns&&selectedTableColumns?.forEach((table)=>{
            if(table?.table_name?.toLowerCase()===tableName?.toLowerCase()){
                tableNameExists=true
            }else{
                tableNameExists=false
            }
        })

        return tableNameExists
    }

    const tableColumnSelectHandleChange=(tableName,e,value)=>{
        let prevValue=selectedTableColumns&&selectedTableColumns?.filter((value)=>{
            if(value?.table_name?.toLowerCase()!==tableName?.toLowerCase()){
                return value
            }
        })

        if(value?.toLowerCase()!=="all"){
            if(e?.target?.checked===true){
            if(selectedTableColumns?.length<=0||selectedTableColumns===undefined||selectedTableColumns===null){
                setSelectedTableColumns([{table_name:tableName,column:[value]}])
            }else if(tableNameExist(tableName)&&(!getSelectedColumnValueUsingTableName(tableName)?.includes(value))){
                let obj={}
                selectedTableColumns&&selectedTableColumns?.forEach((columnValue)=>{
                    if(columnValue?.table_name?.toLowerCase()===tableName?.toLowerCase()){
                    obj={...columnValue,column:[...columnValue?.column,value]}
                    }
                })
                setSelectedTableColumns([...prevValue,obj])
            }else if(!tableNameExist(tableName)){
                setSelectedTableColumns([...selectedTableColumns,{table_name:tableName,column:[value]}])
            }
            }else if(e?.target?.checked===false){
                let currentColumnValue=getSelectedColumnValueUsingTableName(tableName)&&getSelectedColumnValueUsingTableName(tableName)?.filter((columnValue)=>{
                    if(columnValue!==value){
                        return value
                    }
                })

                setSelectedTableColumns([...prevValue,{table_name:tableName,column:currentColumnValue}])
            }
        }else if(value?.toLowerCase()==="all"){
            if(e?.target?.checked===false){
                setSelectedTableColumns([...prevValue,{table_name:tableName,column:[]}])
            }else if(e?.target?.checked===true){
                let allColumnList=[]
               tableColumnList&&tableColumnList?.forEach((tableValue)=>{
                    if(tableValue?.table_name?.toLowerCase()===tableName?.toLowerCase()){
                        allColumnList=tableValue?.column
                    }
                })
                setSelectedTableColumns([...prevValue,{table_name:tableName,column:allColumnList}])
            }
        }
    }

    const closeModal=()=>{
        setModal(false)
        setListOpen({})
        setTableColumnList([])
    }

    const onSelect=()=>{
        dispatch(SchemaMigratePut({
            table_columns:selectedTableColumns
          }))
    }

    console.log('qqq-1',selectedTableColumns)

  return (
    <div className='multiple-table-column-list-parent-container'>
        <p className='multiple-table-column-list-title'>Select Table Columns</p>

        <div className='multiple-table-column-list-table-parent-container'>
            <Spinner size="lg" data={tableColumnList}/>
            {tableColumnList&&tableColumnList?.map((table,key)=>{
                return(
                    <div className='multiple-table-column-list-table-inner-parent-container'>
                    <p className={`${!listOpen?.[table?.table_name]?'multiple-table-column-list-table-title-close-state':'multiple-table-column-list-table-title'}`}onClick={()=>{listOpenAndClose(table?.table_name)}}>{table?.table_name}</p>
                   {listOpen?.[table?.table_name] && 
                            <table key={key}>
                            <tr className='multiple-table-column-list-table-tr'>
                                <th className='multiple-table-column-list-table-th' key={getSelectedColumnValueUsingTableName(table?.table_name)}> <Checkbox onChange={(e)=>tableColumnSelectHandleChange(table?.table_name,e,'ALL')} key={listOpen?.[table?.table_name]} defaultChecked={getSelectedColumnValueUsingTableName(table?.table_name)?.length===table?.column?.length?true:false}> ALL </Checkbox></th>
                                <th className='multiple-table-column-list-table-th column-listing-table-width-100'>Column Name</th>
                            </tr>
                            {table?.column&&table?.column?.map((column,index)=>{
                        return(
                                    <tr className='multiple-table-column-list-table-tr' key={index}>
                                    <td className='multiple-table-column-list-table-td' key={getSelectedColumnValueUsingTableName(table?.table_name)}><Checkbox key={listOpen?.[table?.table_name]} onChange={(e)=>tableColumnSelectHandleChange(table?.table_name,e,column)} defaultChecked={getSelectedColumnValueUsingTableName(table?.table_name)?.includes(column)?true:false}></Checkbox></td>
                                    <td className='multiple-table-column-list-table-td' key={index}>{column}</td>
                                </tr>
                                 )
                                })}
                        </table>}
                </div>
                )
            })}
        </div>

        <div className='multiple-table-column-list-table-button-container'>
        <Button className='multiple-table-column-list-table-buttons cancelButton' onClick={closeModal}>Cancel</Button>
        <Button className='multiple-table-column-list-table-buttons' type='primary' onClick={onSelect}>Select</Button>
        </div>
    </div>
  )
}

export default MultipleTableColumnList