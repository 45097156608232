
import React, { Fragment, useEffect, useState } from 'react'
import Header from "../../components/header/Header";
import CommonTable from "../../components/table/CommonTable";
import { useSelector, useDispatch } from 'react-redux'
import { DvisualCreatePop, DVisualGet, DvisualGetId, DVisualGetOne, DvisualEditPop, DvisualViewPop } from '../../redux/reducer/Visualization/DVisualization'
import { DVisualdtCreatePop, DVisualdtGet, DVisualdtEditPop, DVisualdtViewPop, DVisualdtGetId, DVisualdtGetOne } from '../../redux/reducer/Visualization/DVisualizationDetail'
import { DVisualreportCreatePop, DVisualreportAddForm, DVisualreportGet, DVisualreportEditPop, DVisualreportViewPop, DVisualreportGetId, DVisualreportGetOne } from '../../redux/reducer/Visualization/DVisualReport'
import VisualAdd from '../../components/modal/visualization/Vconfig/Add/Visual';
import VisualEdit from '../../components/modal/visualization/Vconfig/Edit/VconfigEdit'
import VisualView from '../../components/modal/visualization/Vconfig/View/Vconfigview'
import VisualdtAdd from '../../components/modal/visualization/VconfigDeatail/Add/VconfigdtAdd'
import VisualdtEdit from '../../components/modal/visualization/VconfigDeatail/Edit/VconfigdtEdit'
import VisualdtView from '../../components/modal/visualization/VconfigDeatail/View/VconfigdtView'
import VisualReportAdd from '../../components/modal/visualization/Vreportlist/Add/ReportlistAdd'
import ReportView from '../../components/modal/visualization/Vreportlist/View/ReportlistView'
import ReportEdit from '../../components/modal/visualization/Vreportlist/Edit/ReportlistEdit'
import { Drawer, Button, Space, Modal } from 'antd';
import { Switch } from 'antd';
import * as Fi from 'react-icons/fi';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import moment from 'moment'
import Footer from '../../components/footer/Footer';
// import axios from 'axios';


function DVisual(props) {
    console.log(props)
    const { pages } = props

    // const [fileUrl, setFileUrl] = useState('');
    // const [showModal, setShowModal] = useState(false);
    // const [load, setLoad] = useState(false);

    const dispatch = useDispatch()
    const SearchData = useSelector((state) => state.Header.Search.value)
    const footerPage = useSelector((state) => state.Header.Footer.page)
    const footerPageSize = useSelector((state) => state.Header.Footer.pagesize)
    // const Data = useSelector((state) => state.Connection.Data)
    // const conndtldata = useSelector((state) => state.ConnectionDetail.ConData)
    const DVisualGetAll = useSelector((state) => state.DVisual.dVisualGetData)
    const DVisualdtGetAll = useSelector((state) => state.DVisualdt.DvisualdtData)
    console.log(DVisualGetAll);
    const VisualCreate = useSelector((state) => state.DVisual.Vmodal.DvisualCreatePop)
    const VisualCreateEdit = useSelector((state) => state.DVisual.Vmodal.DvisualEditPop)
    const VisualCreateView = useSelector((state) => state.DVisual.Vmodal.DvisualViewPop)
    const VisualCreatedt = useSelector((state) => state.DVisualdt.Vmodaldt.DVisualdtCreatePop)
    const VisualCreatedtEdit = useSelector((state) => state.DVisualdt.Vmodaldt.DVisualdtEditPop)
    const VisualCreatedtView = useSelector((state) => state.DVisualdt.Vmodaldt.DVisualdtViewPop)
    const VisualReport = useSelector((state) => state.DVisualreport.Vreport.DVisualreportCreatePop)
    const VisualReportEdit = useSelector((state) => state.DVisualreport.Vreport.DVisualreportEditPop)
    const VisualReportView = useSelector((state) => state.DVisualreport.Vreport.DVisualreportViewPop)
    const reportData = useSelector((state) => state.DVisualreport.DVisualreportGetData)
    //  const fileUrl = useSelector(state => state.DVisualreport.DVisualreportGetOne);
    const fileUrl = useSelector(state => state.DVisualreport.DVisualreportAddData.file_url);
    console.log("fileurl", fileUrl);

    const [modalVisible, setModalVisible] = useState(false);
    const [pdfLink, setPdfLink] = useState('');
    const showModal = () => {
        setPdfLink(fileUrl);
        setModalVisible(true);
    };

    const closeModal = () => {
        setPdfLink(fileUrl);
        setModalVisible(false);
    };

    useEffect(() => {
        dispatch(DVisualGet(true))
        dispatch(DVisualdtGet(true))
        dispatch(DVisualreportGet(true))
    }, [])

    const VisualConfigColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '80px',
            // render: (text, object, index) => { return index + 1 },
            render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },
            // filteredValue: [searchData],
            // onFilter: (value, record) => {
            //     return String(record.name).toLowerCase().includes(value.toLowerCase());
            // },
        },
        {
            title: 'Configuration Name',
            dataIndex: 'name',
            width: '200px',
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            width: '200px',
        },
        {
            title: 'Start Date',
            dataIndex: 'sdate',
            // sorter: (a, b) => { return a.sdate.localeCompare(b.sdate) },
            width: '170px'
        },
        {
            title: 'End Date',
            dataIndex: 'edate',
            // sorter: (a, b) => { return a.edate.localeCompare(b.edate) },
            width: '170px'
        },
        {
            title: 'Active',
            dataIndex: 'active',
            width: '130px'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '70px'
        },
    ]

    const VisualConfigdtColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '80px',
            //  render: (text, object, index) => { return index + 1 },
            render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },

        },
        {
            title: 'Configuration Detail Name',
            dataIndex: 'configdetails',
            key: 'configdetails',
            // sorter: (a, b) => a.conname.localeCompare(b.conname),
            width: "300px"
        },
        {
            title: 'Configuration Name',
            dataIndex: 'configname',
            key: 'configname',
            sortDirections: ['descend', 'ascend'],
            width: "200px"
        },

        {
            title: 'Start Date',
            dataIndex: 'startdate',
            key: 'startdate',
            // sorter: (a, b) => a.startdate.localeCompare(b.startdate),
            sortDirections: ['descend', 'ascend'],
            width: "150px"
        },
        {
            title: 'End Date',
            dataIndex: 'enddate',
            key: 'enddate',
            // sorter: (a, b) => a.enddate.localeCompare(b.enddate),
            sortDirections: ['descend', 'ascend'],
            width: "180px"
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width: "100px"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "70px"
        },
    ];

    const VisualReportColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '80px',
            //  render: (text, object, index) => { return index + 1 },
            render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },

        },
        {
            title: 'Run.Id',
            dataIndex: 'runid',
            width: '80px',
            //  render: (text, object, index) => { return index + 1 },
            render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },

        },
        {
            title: 'Report Name',
            dataIndex: 'reportname',
            key: 'reportname',
            sortDirections: ['descend', 'ascend'],
            width: "200px"
        },
        {
            title: 'WorkBook Name',
            dataIndex: 'workbookname',
            key: 'workbookname',
            width: "300px"
        },
        {
            title: 'Configuration Detail Name',
            dataIndex: 'configdetails',
            key: 'configdetails',
            // sorter: (a, b) => a.conname.localeCompare(b.conname),
            width: "300px"
        },
        {
            title: 'Start Date',
            dataIndex: 'startdate',
            key: 'startdate',
            // sorter: (a, b) => a.startdate.localeCompare(b.startdate),
            sortDirections: ['descend', 'ascend'],
            width: "150px"
        },
        {
            title: 'End Date',
            dataIndex: 'enddate',
            key: 'enddate',
            // sorter: (a, b) => a.enddate.localeCompare(b.enddate),
            sortDirections: ['descend', 'ascend'],
            width: "180px"
        },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     key: 'active',
        //     width: "100px"
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "70px"
        },
    ];




    const visualFilterData = DVisualGetAll.filter((val) => {
        if (SearchData === '' || val.dvisual_config_name.toString().toLowerCase().includes(SearchData.toLowerCase())) {
            return true;
        }
        return false;
    });

    const visualdtFilterData = DVisualdtGetAll.filter((val) => {
        if (SearchData === '' || val.connection_detail.toString().toLowerCase().includes(SearchData.toLowerCase())) {
            return true;
        }
        return false;
    });

    // const ReportFilterData = reportData.filter((val) => {
    //     if (SearchData === '' || (val.dvisual_report_name && val.dvisual_report_name.toString().toLowerCase().includes(SearchData.toLowerCase()))) {
    //         return true;
    //     } 
    //     return false;
    // });

    const ReportFilterData = reportData.filter((val) => {
        if (SearchData === '') {
            return val
        } else if (val.dvisual_report_name.toString().toLowerCase().includes(SearchData.toLowerCase())) {
            return val
        }
    })



    const dvisual = visualFilterData.map((value) => {
        // console.log(value)
        return (
            {
                name: value.dvisual_config_name,
                desc: value.description,
                sdate: moment.utc((value.start_date)).format('MM-DD-YYYY'),
                edate: moment.utc((value.end_date)).format('MM-DD-YYYY'),
                active: (<Switch checked={value.is_active === true ? true : false} />),
                action: (
                    <div className='Action_Icons'>
                        {/* {configurationAccess.map((val, key) => {
                            return ( */}
                        <Fi.FiEdit size={16} onClick={() => {
                            dispatch(DvisualGetId({ singleData: value.id }))
                            dispatch(DVisualGetOne())
                            dispatch(DvisualEditPop())
                        }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                        {/* )
                        })} */}
                        <Fa.FaEye size={18} onClick={() => {
                            dispatch(DvisualGetId({ singleData: value.id }))
                            dispatch(DVisualGetOne())
                            dispatch(DvisualViewPop())
                        }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                    </div>
                )

            }
        )
    })
    //console.log(dTrans)


    // const configname = (e) => {
    //     console.log(e)
    //     const filteredConfig = DVisualdtGetAll.filter(value => e === value.id);
    //     const configNames = filteredConfig.map(value => value.dvisual_config_name);
    //     return configNames;

    // };
    const configname = (e) => {
        const configname = []
        DVisualGetAll.map((value) => {
            if (e === (value.id)) {
                configname.push(value.dvisual_config_name)
                // console.log(configname);
            }
        }
        )
        return configname
    }


    const dvisualdtData = visualdtFilterData.map((val) => {
        // console.log(val)
        return ({
            configname: configname(val.dvisual_config_det_id),
            configdetails: val.dvisual_config_detail,
            startdate: moment.utc((val.start_date)).format('MM-DD-YYYY'),
            enddate: moment.utc((val.end_date)).format('MM-DD-YYYY'),
            active: (
                <Switch checked={val.is_active === true ? true : false} />
            ),
            action: (
                <div className='Action_Icons'>
                    {/* {connectionAccess.map((value, key) => {
                        return ( */}
                    <Fi.FiEdit size={16} onClick={() => {
                        dispatch(DVisualdtGetId({ singleData: val.id }))
                        dispatch(DVisualdtGetOne())
                        dispatch(DVisualdtEditPop())
                    }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                    {/* )
                    })} */}
                    <Fa.FaEye size={18} onClick={() => {
                        dispatch(DVisualdtGetId({ singleData: val.id }))
                        dispatch(DVisualdtGetOne())
                        dispatch(DVisualdtViewPop())
                    }} style={{ cursor: 'pointer', marginRight: '20px' }} />

                    {/* { <Ai.AiFillPlayCircle size={18} 
                            style={{ cursor: 'pointer', marginRight: '20px' }} />} */}
                </div>
            )
        })
    })


    const configreportname = (e) => {
        const configreportname = []
        DVisualdtGetAll.map((value) => {
            if (e === (value.id)) {
                configreportname.push(value.dvisual_config_detail)
                // console.log(configname);
            }
        }
        )
        return configreportname
    }

    const ReportData = ReportFilterData.map((val) => {
        return ({

            runid: val.id,
            reportname: val.dvisual_report_name,
            workbookname: val.workbook_name,
            configdetails: configreportname(val.dvisual_config_detail_id),
            startdate: moment.utc((val.start_date)).format('MM-DD-YYYY'),
            enddate: moment.utc((val.end_date)).format('MM-DD-YYYY'),
            // active: (
            //     <Switch checked={val.is_active === true ? true : false} />
            // ),
            action: (
                <div className='Action_Icons'>
                    {/* {pipelineAccess.map((value, key) => {
                        return ( */}
                    <Fi.FiEdit size={16} onClick={() => {
                        dispatch(DVisualreportGetId({ singleData: val.id }))
                        dispatch(DVisualreportGetOne())
                        dispatch(DVisualreportEditPop())
                    }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                    {/* )
                    })} */}
                    <Fa.FaEye size={18} onClick={() => {
                        dispatch(DVisualreportGetId({ singleData: val.id }))
                        dispatch(DVisualreportGetOne())
                        dispatch(DVisualreportViewPop())
                    }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                    {<Ai.AiFillPlayCircle size={18} onClick={() => {
                        // handleButtonClick()

                        showModal();
                    }}
                        style={{ cursor: 'pointer', marginRight: '20px' }} />}
                </div>
            )
        })
    })

    const handleButtonClick = () => {
        // Check if the file URL exists before triggering the action
        if (fileUrl) {
            // Perform the action you want, e.g., opening a new tab/window
            window.open(fileUrl, '_blank');
        }
    };

    const handleDownload = () => {
        if (pdfLink) {
            const link = document.createElement('a');
            link.href = pdfLink;
            link.target = '_blank';
            link.download = 'pdf.pdf';
            link.click();
            closeModal();
        }
    };

    const DataVisual = (page, pageSize) => {
        return dvisual.slice((page - 1) * pageSize, page * pageSize);
    };

    const DataVisualdt = (page, pageSize) => {
        return dvisualdtData.slice((page - 1) * pageSize, page * pageSize);
    };

    const DataReport = (page, pageSize) => {
        return ReportData.slice((page - 1) * pageSize, page * pageSize);
    };



    const VisualCreateModal = () => {
        dispatch(DvisualCreatePop())
    }

    const VisualEditModal = () => {
        dispatch(DvisualEditPop())
    }

    const VisualViewModal = () => {
        dispatch(DvisualViewPop())
    }

    const VisualCreatedtModal = () => {
        dispatch(DVisualdtCreatePop())
    }

    const VisualEditdtModal = () => {
        dispatch(DVisualdtEditPop())
    }
    const VisualViewdtModal = () => {
        dispatch(DVisualdtViewPop())
    }
    const VisualReportModal = () => {
        dispatch(DVisualreportCreatePop())
        dispatch(DVisualreportAddForm({ Search: true, DVisualreportAddForm: false }))
    }

    const VisualReportEditModal = () => {
        dispatch(DVisualreportEditPop())
    }

    const VisualReportViewModal = () => {
        dispatch(DVisualreportViewPop())
    }

    return (
        <Fragment>
            <Modal title="Report Data" visible={modalVisible} onCancel={closeModal} footer={[<Button key="cancel" onClick={closeModal}> Cancel</Button>, <Button type='primary' key="download" onClick={handleDownload}>Download</Button>]} width={800}
                bodyStyle={{ height: '450px', overflowY: 'auto', overflowX: 'hidden' }} style={{ marginBottom: '20px', marginTop: '-80px', marginLeft: '300px' }}>
                <iframe src={pdfLink} title="PDF Preview" width="100%" height="100%" frameBorder="0"></iframe>
            </Modal>
            <Header />
            <CommonTable

                VisualConfigColumn={VisualConfigColumn} VisualConfigData={DataVisual(footerPage, footerPageSize)}
                VisualConfigdtColumn={VisualConfigdtColumn} VisualConfigdtData={DataVisualdt(footerPage, footerPageSize)}
                VisualReportColumn={VisualReportColumn} VisualReportData={DataReport(footerPage, footerPageSize)} />
            <Drawer title=" Create D-Visual-Configuration" open={VisualCreate} maskClosable={false} onClose={VisualCreateModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualCreateModal}>Cancel</Button>
                    </Space>
                }
                key={VisualCreate ? 'drawer-open' : 'drawer-closed'} >
                <VisualAdd />
            </Drawer>

            <Drawer title=" Edit D-Visual-Configuration" open={VisualCreateEdit} maskClosable={false} onClose={VisualEditModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualEditModal}>Cancel</Button>
                    </Space>
                }
                key={VisualCreateEdit ? 'drawer-open' : 'drawer-closed'} >
                <VisualEdit />
            </Drawer>

            <Drawer title=" View D-Visual-Configuration" open={VisualCreateView} maskClosable={false} onClose={VisualViewModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualViewModal}>Cancel</Button>
                    </Space>
                }
                key={VisualCreateView ? 'drawer-open' : 'drawer-closed'} >
                <VisualView />
            </Drawer>



            <Drawer title=" Create D-Visual-ConfigurationDetails" open={VisualCreatedt} maskClosable={false} onClose={VisualCreatedtModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualCreatedtModal}>Cancel</Button>
                    </Space>
                }
                key={VisualCreatedt ? 'drawer-open' : 'drawer-closed'}>
                <VisualdtAdd />
            </Drawer>

            <Drawer title=" Edit D-Visual-ConfigurationDetails" open={VisualCreatedtEdit} maskClosable={false} onClose={VisualEditdtModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualEditdtModal}>Cancel</Button>
                    </Space>
                }
                key={VisualCreatedtEdit ? 'drawer-open' : 'drawer-closed'}>
                <VisualdtEdit />
            </Drawer>


            <Drawer title=" View D-Visual-ConfigurationDetails" open={VisualCreatedtView} maskClosable={false} onClose={VisualViewdtModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualViewdtModal}>Cancel</Button>
                    </Space>
                }
                key={VisualCreatedtView ? 'drawer-open' : 'drawer-closed'}>
                <VisualdtView />
            </Drawer>

            <Drawer title=" Create D-Visual-ReportList" maskClosable={false} open={VisualReport} onClose={VisualReportModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualReportModal}>Cancel</Button>
                    </Space>
                }
                key={VisualReport ? 'drawer-open' : 'drawer-closed'}>
                <VisualReportAdd />
            </Drawer>

            <Drawer title=" Edit D-Visual-ReportList" maskClosable={false} open={VisualReportEdit} onClose={VisualReportEditModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualReportEditModal}>Cancel</Button>
                    </Space>
                }
                key={VisualReportEdit ? 'drawer-open' : 'drawer-closed'}>
                <ReportEdit />
            </Drawer>

            <Drawer title=" View D-Visual-ReportList" maskClosable={false} open={VisualReportView} onClose={VisualReportViewModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}

                extra={
                    <Space >
                        <Button onClick={VisualReportViewModal}>Cancel</Button>
                    </Space>
                }
                key={VisualReportView ? 'drawer-open' : 'drawer-closed'}>
                <ReportView />
            </Drawer>

            <Footer VisualData={visualFilterData.length} VisualdtData={visualdtFilterData.length} DVisualreport={ReportFilterData.length} />
        </Fragment>
    )
}

export default DVisual