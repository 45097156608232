import React, { useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const Spinner = (props) => {

    const {size='xs',data}=props

    const spinnerSize={
        xs:24,
        sm:30,
        md:35,
        lg:40,
        xl:45,
        xxl:50
    }

    const checkParentData=useMemo(()=>{
        if((data?.length>0||Object?.values(data)?.length>0)&&data!==undefined&&data!==null){
            return true
        }else{
            return false
        }
    },[props])
    return(
        <React.Fragment>
            {checkParentData===false&&<div style={{width:'95%',display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',top:'0',bottom:'0'}}>
<Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: spinnerSize?.[size],
        }}
        spin
      />
    }
  />
  </div>}
  </React.Fragment>
    )
};
export default React.memo(Spinner);