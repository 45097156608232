import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from 'antd';
import { DValidate,Token } from '../../../api/BackEndURL';

let id = ''
let key = 'updatable'
export const dValidateGet = createAsyncThunk("DValidateGet", async () => {
        return await DValidate.method.get(DValidate.URL.get,{headers:{"Authorization" : Token}}).then((response) => response.data) 
})

export const dValidatePost = createAsyncThunk("DValidatePost", async (Data) => {
    return await DValidate.method.post(`${DValidate.URL.post}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const dValidateGetOne = createAsyncThunk("DValidateGetOne", async () => { 
       return await DValidate.method.get(`${DValidate.URL.get}${id}`,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const dValidatePut = createAsyncThunk("DValidatePut", async (Data) => {
    return await DValidate.method.put(`${DValidate.URL.put}${id}`, Data,{headers:{"Authorization" : Token}}).then((response) => response.data)
})

export const createSuccess = () => {
    message.success({ content: 'Created Successfully!', key, duration: 2, });
};

export const updateSuccess = () => {
    message.success({ content: 'Updated Successfully!', key, duration: 2, });
};

export const DValidateReducer = createSlice({
    name: "DValidate",
    initialState: {
        DValidateGetData: [],
        DValidateAddData: {
            config_name: '', 
            transform_name: '', 
            config_id: '',
            start_date: '',
            end_date: '',
            is_active: false,
          
        },
        DValidateUpdate: {
            config_name: '', 
            transform_name: '', 
            // Dtranfig_id: '',
            start_date: '',
            end_date: '',
            is_active: '',
        },
        newForm: {
            DValidateEditPop: false,
            DValidateViewPop: false,
            DValidateCreatePop: false,
            Search: true,
            //DValidateAddForm: false,
        },
        loader: {
            Loader: false
        },
        singleData: "",
        getOneData: []
    },
    reducers: {
        DValidateGetId: (state, action) => {
            state.singleData = action.payload.singleData
            id = state.singleData
        },
        DValidateAddForm: (state, action) => {
            state.newForm.Search = action.payload.Search
            state.newForm.DValidateAddForm = action.payload.DValidateAddForm
        },
        DValidateEditPop: (state, action) => {
            state.newForm.DValidateEditPop === false ? state.newForm.DValidateEditPop = true : state.newForm.DValidateEditPop = false
        },
        DValidateViewPop: (state, action) => {
            state.newForm.DValidateViewPop === false ? state.newForm.DValidateViewPop = true : state.newForm.DValidateViewPop = false
        },
        DValidateCreatePop: (state, action) => {
            state.newForm.DValidateCreatePop === false ? state.newForm.DValidateCreatePop = true : state.newForm.DValidateCreatePop = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(dValidateGet.pending, (state, action) => {
            state.loader.Loader = true
        });
        builder.addCase(dValidateGet.fulfilled, (state, action) => {
            state.loader.Loader = false
            state.DValidateGetData = action.payload
        }) 
        builder.addCase(dValidateGetOne.fulfilled, (state, action) => {
            state.getOneData = action.payload
        })
        builder.addCase(dValidatePost.fulfilled, (state, action) => {
            createSuccess(true)
            state.newForm.DValidateCreatePop=false;
            state.newForm.DValidateAdd=false;
            state.newForm.Search=true;
            state.DValidateAddData.config_name = action.payload.config_name;
            state.DValidateAddData.transform_name = action.payload.transform_name;
            state.DValidateAddData.config_id = action.payload.config_id;
            state.DValidateAddData.start_date = action.payload.start_date;
            state.DValidateAddData.end_date = action.payload.end_date;
            state.DValidateAddData.is_active = action.payload.is_active;
            state.newForm.DValidateCreatePop = action.payload.DValidateCreatePop;
            // state.dTFormAddData.tenant_id=action.payload.tenant_id;
        })
        builder.addCase(dValidatePut.fulfilled, (state, action) => {
            updateSuccess(true)
            state.DValidateUpdate.config_name = action.payload.config_name;
            state.DValidateUpdate.transform_name = action.payload.transform_name;
            state.DValidateUpdate.config_id = action.payload.config_id;
            state.DValidateUpdate.start_date = action.payload.start_date;
            state.DValidateUpdate.end_date = action.payload.end_date;
            state.DValidateUpdate.is_active = action.payload.is_active;
            state.newForm.DValidateEditPop = action.payload.DValidateEditPop
        })
    }
})

export const { DValidateEditPop, DValidateViewPop, DValidateCreatePop, DValidateGetId ,DValidateAddForm} = DValidateReducer.actions
export default (DValidateReducer).reducer