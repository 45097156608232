import React, { Fragment, useEffect } from 'react'
import Loader from "../../components/loader/Loader"
import Header from '../../components/header/Header'
import CommonTable from '../../components/table/CommonTable'
import { Switch, Modal, Drawer, Button, Space } from 'antd';
import Footer from '../../components/footer/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { connectionGet, ConnPopupCreate, ConnPopupEdit, ConnPopupView, GetId, connectionGetId } from '../../redux/reducer/dStreaming/DStreamCon'
import { ConndtlPopupCreate, ConndtlPopupEdit, ConndtlPopupView, connectionDetailGet, ConndtlGetId, connectionDetailGetId } from '../../redux/reducer/dStreaming/DStreamConDetail'
import StreamConnectionAdd from '../../components/modal/streaming/streamingConnection/Add/Add';
import StreamConnectionEdit from '../../components/modal/streaming/streamingConnection/Edit/Edit';
import StreamConnectionView from '../../components/modal/streaming/streamingConnection/View/View';
import StreamConnectiondtlAdd from '../../components/modal/streaming/streamingConnectionDetails/Add/Add'
import StreamConnectiondtlEdit from '../../components/modal/streaming/streamingConnectionDetails/Edit/Edit';
import StreamConnectiondtlView from '../../components/modal/streaming/streamingConnectionDetails/View/View';
import * as Ai from 'react-icons/ai';
import * as Fi from 'react-icons/fi'
import * as Fa from 'react-icons/fa'
import moment from 'moment';

function Dstreaming() {

  const dispatch = useDispatch()

  const searchData = useSelector((state) => state.Header.Search.value)
  const footerPage = useSelector((state) => state.Header.Footer.page)
  const footerPageSize = useSelector((state) => state.Header.Footer.pagesize)
  const connData = useSelector((state) => state.DStreamingCon.Data)
  console.log(connData)
  const conndtldata = useSelector((state) => state.DStreamConDetail.ConData)
  // console.log(conndtldata)

  const connectionAddModalOpen = useSelector((state) => state.DStreamingCon.modal.ConnPopupCreate)
  const connectionEditModalOpen = useSelector((state) => state.DStreamingCon.modal.ConnPopupEdit)
  const connectionViewModalOpen = useSelector((state) => state.DStreamingCon.modal.ConnPopupView)
  const connectiondtlAddModalOpen = useSelector((state) => state.DStreamConDetail.Condtlmodal.ConndtlPopupCreate)
  const connectiondtlEditModalOpen = useSelector((state) => state.DStreamConDetail.Condtlmodal.ConndtlPopupEdit)
  const connectiondtlViewModalOpen = useSelector((state) => state.DStreamConDetail.Condtlmodal.ConndtlPopupView)

  const connectionLoader = useSelector((state) => state.Connection.loader.Loader)

  useEffect(() => {
    dispatch(connectionGet(true))
    dispatch(connectionDetailGet(true))
  }, [])

  const connectionAddPopup = () => {
    dispatch(ConnPopupCreate())
    window.location.reload()
  }

  const connectionEditPopup = () => {
    dispatch(ConnPopupEdit())
  }

  const connectionViewPopup = () => {
    dispatch(ConnPopupView())
  }

  const connectiondtlAddPopup = () => {
    dispatch(ConndtlPopupCreate())
    window.location.reload()

  }
  const connectiondtlEditPopup = () => {
    dispatch(ConndtlPopupEdit())
  }

  const connectiondtlViewPopup = () => {
    dispatch(ConndtlPopupView())
  }


  const connectionColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: '80px',
      // render: (text, object, index) => { return index + 1 },
      render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },
      // filteredValue: [searchData],
      // onFilter: (value, record) => {
      //     return String(record.name).toLowerCase().includes(value.toLowerCase());
      // },
    },
    {
      title: 'Connection Name',
      dataIndex: 'name',
      width: '200px',
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Description',
      dataIndex: 'des',
      width: '200px',
    },
    {
      title: 'Start Date',
      dataIndex: 'sdate',
      // sorter: (a, b) => { return a.sdate.localeCompare(b.sdate) },
      width: '170px'
    },
    {
      title: 'End Date',
      dataIndex: 'edate',
      // sorter: (a, b) => { return a.edate.localeCompare(b.edate) },
      width: '170px'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '130px'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '70px'
    },
  ]

  const connectionDetailsColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: '80px',
      //  render: (text, object, index) => { return index + 1 },
      render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },

    },
    {
      title: 'Connection Detail Name',
      dataIndex: 'conndetails',
      key: 'conndetails',
      // sorter: (a, b) => a.conname.localeCompare(b.conname),
      width: "300px"
    },
    {
      title: 'Connection Name',
      dataIndex: 'conname',
      key: 'conname',
      sortDirections: ['descend', 'ascend'],
      width: "200px"
    },

    {
      title: 'Start Date',
      dataIndex: 'startdate',
      key: 'startdate',
      // sorter: (a, b) => a.startdate.localeCompare(b.startdate),
      sortDirections: ['descend', 'ascend'],
      width: "150px"
    },
    {
      title: 'End Date',
      dataIndex: 'enddate',
      key: 'enddate',
      // sorter: (a, b) => a.enddate.localeCompare(b.enddate),
      sortDirections: ['descend', 'ascend'],
      width: "180px"
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: "100px"
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: "70px"
    },
  ];

  const connectionFilterData = connData.filter((val) => {
    if (searchData === '') {
      return val
    } else if (val.connection_name.toString().toLowerCase().includes(searchData.toLowerCase())) {
      return val
    }
  })

  const connectionDetailsFilterData = conndtldata.filter((val) => {
    if (searchData === '') {
      return val
    } else if (val.connection_detail.toString().toLowerCase().includes(searchData.toLowerCase())) {
      return val
    }
  })

  const connectionData = connectionFilterData.map((val) => {
    return ({
      name: val.connection_name,
      conndetails: val.connection_detail,
      des: val.description,
      sdate: moment.utc((val.start_date)).format('MM-DD-YYYY'),
      edate: moment.utc((val.end_date)).format('MM-DD-YYYY'),
      active: (
        <Switch checked={val.is_active === true ? true : false} />
      ),
      action: (
        <div className='Action_Icons'>
          {/* {connectionAccess.map((value, key) => {
                  return ( */}
          <Fi.FiEdit size={16} onClick={() => {
            dispatch(GetId({ singleData: val.id }))
            dispatch(connectionGetId())
            dispatch(ConnPopupEdit())
          }} style={{ cursor: 'pointer', marginRight: '10px' }} />
          {/* )
              })} */}
          <Fa.FaEye size={18} onClick={() => {
            dispatch(GetId({ singleData: val.id }))
            dispatch(connectionGetId())
            dispatch(ConnPopupView())
          }} style={{ cursor: 'pointer', marginRight: '20px' }} />
        </div>
      )
    })
  })

  const conname = (e) => {
    const conname = []
    connData.map((value) => {
      if (e === (value.id)) {
        conname.push(value.connection_name)
      }
      console.log(conname)
    }
    )
    return conname
  }

  const connectiondtlData = connectionDetailsFilterData.map((val) => {
    return ({
      id: val.id,
      conname: conname(val.connection_id),
      conndetails: val.connection_detail,

      startdate: moment.utc((val.start_date)).format('DD-MM-YYYY'),
      enddate: moment.utc((val.end_date)).format('DD-MM-YYYY'),
      active: (
        <Switch checked={val.is_active === true ? true : false} />
      ),
      action: (
        <div className='Action_Icons'>
          {/* {connectionAccess.map((value, key) => {
                  return ( */}
          <Fi.FiEdit size={16} onClick={() => {
            dispatch(ConndtlGetId({ singleData: val.id }))
            dispatch(connectionDetailGetId())
            dispatch(ConndtlPopupEdit())
          }} style={{ cursor: 'pointer', marginRight: '10px' }} />
          {/* )
              })} */}
          <Fa.FaEye size={18} onClick={() => {
            dispatch(ConndtlGetId({ singleData: val.id }))
            dispatch(connectionDetailGetId())
            dispatch(ConndtlPopupView())
          }} style={{ cursor: 'pointer', marginRight: '10px' }} />

          <Ai.AiFillPlayCircle size={19}
            style={{ cursor: 'pointer', marginRight: '10px' }} />
        </div>
      )
    })

  })

  const connectiondata = (page, pageSize) => {
    return connectionData.slice((page - 1) * pageSize, page * pageSize);
  };

  const connectiondtldata = (page, pageSize) => {
    return connectiondtlData.slice((page - 1) * pageSize, page * pageSize);
  };

  // console.log(connectiondtldata);

  return (
    <>
      {connectionLoader ? (
        <Loader />
      ) : (
        <Fragment>

          <Header />
          <CommonTable streamConnectionColumns={connectionColumns} streamConnectionData={connectiondata(footerPage, footerPageSize)} streamConnectionDetailsColumns={connectionDetailsColumns} streamConnectiondtlData={connectiondtldata(footerPage, footerPageSize)} />
          <Drawer title="New Stream Connection" open={connectionAddModalOpen} maskClosable={false} onClose={connectionAddPopup} ariaHideApp={false} width='100vh'
            footer={[
              <div style={{ padding: '15px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                {/* <Button  type='primary'  >Update</Button> */}
              </div>]}
            footerStyle={{ backgroundColor: '#f5f5fd' }}


            extra={
              <Space>
                <Button onClick={connectionAddPopup}>Cancel</Button>
              </Space>
            }
          >
            <StreamConnectionAdd />
          </Drawer>
          <Drawer title="Stream Connection Edit" open={connectionEditModalOpen} maskClosable={false} onClose={connectionEditPopup} ariaHideApp={false} width='100vh'
            footer={[
              <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

              </div>]}
            footerStyle={{ backgroundColor: '#f5f5fd' }}

          >
            <StreamConnectionEdit />
          </Drawer>
          <Drawer title="Stream Connection View" open={connectionViewModalOpen} maskClosable={false} onClose={connectionViewPopup} ariaHideApp={false} width='100vh' footer={null}
          // extra={
          //     <Space>
          //         <Button onClick={connectionViewPopup}>Cancel</Button>
          //     </Space>
          // }
          >
            <StreamConnectionView />
          </Drawer>
          <Drawer title="New Stream Connection Detail" open={connectiondtlAddModalOpen} maskClosable={false} onClose={connectiondtlAddPopup} ariaHideApp={false} width='105vh'
            footer={[
              <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

              </div>]}
            footerStyle={{ backgroundColor: '#f5f5fd' }}
            extra={
              <Space>
                <Button onClick={connectiondtlAddPopup}>Cancel</Button>
              </Space>
            }
          >
            <StreamConnectiondtlAdd />
          </Drawer>
          <Drawer title="Stream Connection Detail Edit" open={connectiondtlEditModalOpen} maskClosable={false} onClose={connectiondtlEditPopup} ariaHideApp={false} width='100vh'
            footer={[
              <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

              </div>]}
            footerStyle={{ backgroundColor: '#f5f5fd' }}
          // extra={
          //     <Space>
          //         <Button onClick={connectiondtlEditPopup}>Cancel</Button>
          //     </Space>
          // }
          >
            <StreamConnectiondtlEdit />
          </Drawer>
          <Drawer title="Stream Connection Detail View" open={connectiondtlViewModalOpen} maskClosable={false} onClose={connectiondtlViewPopup} ariaHideApp={false} width='100vh' footer={null}
          // extra={
          //     <Space>
          //         <Button onClick={connectiondtlViewPopup}>Cancel</Button>
          //     </Space>
          // }
          >
            <StreamConnectiondtlView />
          </Drawer>
          <Footer streamConnData={connectionFilterData.length} streamConndtlData={connectionDetailsFilterData.length} />
        </Fragment>
      )
      }
    </>
  )
}

export default Dstreaming



