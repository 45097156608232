import React, { useState, useEffect, useRef } from 'react'
import { Button, Input, Form, Row, Col, DatePicker, Switch, AutoComplete, message, Modal } from 'antd';
// import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import moment from "moment"
import { useDispatch, useSelector } from 'react-redux'
import {DTransformconfigdtPost} from '../../../../../redux/reducer/transform/DTransformconfigdt'


// import { connectionDetailAdd } from '../../../../../redux/reducer/connection/ConnectionDetailReducer';

export default function TconfigdtlAdd() {
    // let URL = process.env.REACT_APP_URL
    const dispatch = useDispatch()
    const { RangePicker } = DatePicker;
    const [newtconfigname, setNewTconfigname] = useState('')
    const [configdtlsname, setConfigdtlsname] = useState('')
    const [configNamelist, setConfigNamelist] = useState([])
    const [logoname, setLogoname] = useState('')
    const [preview, setPreview] = useState(null);
    const [dates, setDates] = useState([])
    const [isActive, setIsActive] = useState(true)
    const [keyPrams, setKeyParams] = useState([])
    const [openNewConfig, setOpenNewConfig] = useState(false)
    const [configStrings, setconfigStrings] = useState([{ keyValues: "" }])
    const [errConfigStr, setErrConfigStr] = useState(false)
    const [previewtxt, setPreviewtxt] = useState(true)
    const [toggle, setToggle] = useState(false)
    const [alt, setAlt] = useState("")
    let btnRef = useRef();
    const errstr = []

    // const upadateConnection = useSelector((state) => state.Connection.getOneData)
    const dTransformconfigdt = useSelector((state) => state.DTransformconfig.dTConfigGetData)
    console.log(dTransformconfigdt)

    const [configid, setConfigId] = useState()
    const [loading, setLoading] = useState(false);




    const Previouspage = () => {
        setOpenNewConfig(false)
        setKeyParams([])
        setConfigNamelist([])
        setToggle(false)
        setLoading(false);
    }

  

    const CreateConfigDetail = async (e) => {
        const pair = keyPrams.map((item, index) => {
            console.log(index, item)
            return ({ [item.keyPrams]: configStrings[index].keyValues })
        })
        console.log(pair)



        
      

        // let finalObj = {};
        // for (let i = 0; i < pair.length; i++) {
        //     Object.assign(finalObj, pair[i]);
        // }
        // console.log(finalObj)
        // ConnstrError()
        // // && errConnStr==false
        // console.log(keyPrams.length)
        // if (/^[a-zA-Z ]*$/.test(configdtlsname)) {
        //     dispatch(DVisualdtPost({
        //         configuration_name: newvconfigname,
        //         configuration_details: configdtlsname,
        //         con_str: finalObj,
        //         end_date: moment(dates[1]).format('YYYY-MM-DD'),
        //         is_active: isActive,
        //         tenant_id: JSON.parse(sessionStorage.getItem("id")),


        //     }))
        // }
        // else {
        //     alert("Please Fill Input Fields")
        // }
    }


    const CreateConfigurationDetail = async (e) => {
        const pair = keyPrams.map((item, index) => {
            console.log(index, item)
            return ({ [item.keyPrams]: configStrings[index].keyValues })
        })
        console.log(pair)
        let finalObj = {};
        for (let i = 0; i < pair.length; i++) {
            Object.assign(finalObj, pair[i]);
        }
        console.log(finalObj)
        ConnstrError()
        // && errConnStr==false
        console.log(keyPrams.length)
        if (/^[a-zA-Z ]*$/.test(configdtlsname) && configStrings.length > 0) {
            dispatch(DTransformconfigdtPost({
                conn_str:{
                   server:configStrings
                },
                dvisual_config_det_id: newtconfigname,
                dvisual_config_detail: configdtlsname,
                con_str: finalObj,
                start_date: moment(dates[1]).format('YYYY-MM-DD'),
                end_date: moment(dates[1]).format('YYYY-MM-DD'),
                is_active: isActive,
                // tenant_id: JSON.parse(sessionStorage.getItem("id")),


            }))
        }
        // else {
        //     alert("Please Fill Input Fields")
        // }
    }

    

    const ConnstrError = () => {
        if (keyPrams.length > errConfigStr)
            setErrConfigStr(true)
    }

    const openConfigDetailPopup = (val) => {
      TransformNamesList(val.target.alt)
        setAlt(val.target.alt)
        setLogoname(val.target.alt)
        setPreview(val.target.currentSrc)
        setOpenNewConfig(true)
        setPreviewtxt(true)

    }
    // console.log(alt)
    function onSelect(value) {
        const id = dTransformconfigdt.map((values, key) => {
            if (value === values.connection_name) {
                values.key_param.map((values, index) => {
                    keyPrams.push(values)
                    setPreviewtxt(false)
                })
                return setNewTconfigname(values.id)
            }
        }
        )
    }


    // const VisualconfigNamesList = (e) => {
    //     const logo = e;

    //     ConfigNamelistData.map((values) => {
    //         if (logo === values.logo_name) {
    //             setConfigId(values.id);
    //             configNamelist.push({ value: values.connection_name });
    //         }
    //     });
    // };
    // console.log(configNamelist);





    const TransformNamesList = (e) => {
        console.log(e)
        const logo = e
        const name = dTransformconfigdt.map((values, key) => {
            // console.log(values.logo_name+'.'+logo.split('.')?.[1])
            if (logo === values.logo_name + '.png') {
                setConfigId(values.id)
                return configNamelist.push({ value: values.connection_name})
                //   console.log(ret)
                //   return ret
            }
        })
    }
    console.log(dTransformconfigdt)
    console.log(configNamelist)

    function importAll(r) {
        const imagelist = [];
        r.keys().map((item, index) => { imagelist[item.replace('./', '')] = r(item); });
        return imagelist;
    }

    const images = importAll(require.context('../../Tlogoimg', false, /\.(png|jpe?g|svg)$/));

    const [disable, setdisable] = useState(false)

    const SetKeyvalue = (err) => {
        errstr.push(err)
        setconfigStrings([...configStrings, { keyValues: "" }]);
        console.log(configStrings)
    }
    const RemoveConnString = index => {
        const connstrglist = [...configStrings];
        connstrglist.splice(index, 1);
        setconfigStrings(connstrglist);
    };
    const handleKeyValues = (e, index) => {
        const { name, value } = e.target;
        const configstrglist = [...configStrings];
        configstrglist[index][name] = value
        setconfigStrings(configstrglist)
    }
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setIsActive(checked)
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day')
    };



    return (
        <>
            <div className='NewConn-Container'>
                {!openNewConfig &&
                    <>
                        <p style={{ textAlign: "center", fontFamily: 'Nunito' }}>Please Select the Connection</p>
                        <div className='Visualimg-Container' style={{ height: "400px", fontFamily: 'Nunito' }} >
                            {Object.entries(images).map(([key, values]) => {
                                const imageName = key.split('.')[0];
                                { console.log(key) }
                                return (
                                    <div>
                                        <img style={{ width: 135, fontFamily: 'Nunito', height: 90, padding: "20px", cursor: 'pointer' }} alt={key} onClick={openConfigDetailPopup} src={values} />
                                        <p>{imageName}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                {openNewConfig && <div>

                    <Form
                        name="basic"
                        autoComplete="off"
                        layout='vertical'
                        // className='conndlts-Form_Container'
                        style={{ fontFamily: 'Nunito' }}
                    >
                        <Row gutter={[0, 0]} className='conndlts-Form_Container'>

                            <Col lg={{ span: 14, offset: 0 }}>
                                <Form.Item
                                    className="reg-lbl"
                                    label="Configuration Name"
                                    name="Configuration"
                                    rules={[{ required: true, message: 'Enter Your Configuration Name' },
                                    ]}
                                >
                                    <AutoComplete
                                        className='newCon-input-feild'
                                        options={configNamelist}
                                        onSelect={onSelect}
                                        placeholder="Enter Your Configuration Name"

                                        
                                    />
                                </Form.Item>
                            </Col>

                            <Col lg={{ span: 5, offset: 3 }}>
                                <Form.Item
                                    className="reg-lbl"
                                    name="Database"
                                >
                                    <div className='dragdropimg'>
                                        <img className='uploadlogo' src={preview} alt={preview.name} />
                                    </div>
                                </Form.Item>

                            </Col>


                            <Col lg={{ span: 14, offset: 0 }}>
                                <Form.Item
                                    className="reg-lbl"
                                    label="Configuration Detail Name"
                                    name="ConfigurationDetailName"
                                    initialValue={configdtlsname}

                                    rules={[{ required: true, message: 'Enter Your Configuration Detail Name' },
                                    { pattern: /(.*[a-z]){3}/, message: 'Field must atleast 3 character' },
                                    { pattern: /^[a-zA-Z ]*$/, message: 'Enter The Characters' }
                                    ]}
                                >
                                    <Input style={{ textTransform: 'capitalize' }} className='newCon-input-feild' placeholder='Enter Your Connection Name' onChange={(e) => (setConfigdtlsname(e.target.value))} />
                                </Form.Item>
                            </Col>

                            <Col lg={{ span: 14, offset: 0 }}>
                                <Form.Item
                                    // className="reg-lbl"
                                    label="Start Date to End Date"
                                    name="StartDatetoEndDate"
                                    initialValue={dates}
                                    rules={[{ required: true, message: 'Enter Your End Date' },

                                    ]}
                                >
                                    <RangePicker style={{ width: "100%" }} disabledDate={disabledDate} onCalendarChange={(value) => {
                                        console.log(value);
                                        setDates([value[0], value[1]]);
                                    }}
                                        value={dates}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={{ span: 5, offset: 2 }}>
                                <Form.Item
                                    label="Active"
                                    name="Active"
                                // style={{ marginTop: "15px" }}
                                >
                                    <Switch defaultChecked onChange={onChange} />
                                </Form.Item>
                            </Col>
                            <Col lg={{ span: 24, offset: 0 }}>
                                <Form.Item
                                    className="reg-lbl-accordian"
                                    label="Transform String"
                                    name="Database"
                                // rules={[{ required: true, message: 'Enter Your Connection string' },
                                // ]}
                                >

                                    {/* <div className='accordian-sec'>
                                       { previewtxt&& <p style={{marginTop:"60px"}}>Please select the connection name</p>}
                                           {!previewtxt&& ConnectionnamelistData.map((values) => {
                                                if (newconnname === values.id) return (
                                                    <div className='flex-twocontainers'>
                                                        
                                                        <div className='flex-frstcontainer'>
                                                        <label>Key Parameter </label>
                                                        <><br/>
                                                            {values.key_param.map((values, index) => { return (<input style={{ textAlign: "center", marginLeft: "5px", padding: "5px", marginTop: "20px" }} disabled name='keyParams' defaultValue={(values.keyPrams)} />) })}
                                                            </>
                                                        </div>
                                                        <div className='flex-sectcontainer'>
                                                            <label style={{ marginRight: "40px" }}>Key Value </label>
                                                            {connStrings.map((x, i) => {
                                                                return (
                                                                    <div key={i} style={{ textAlign: "center", marginTop: "20px", display: "flex" }}>
                                                                        <input style={{ textAlign: "center", marginLeft: "5px" , padding:"5px"}} placeholder="Enter the value" disabled={disable} name='keyValues' value={x.keyValues} onChange={(e) => { handleKeyValues(e, i) }} />
                                                                        <div className="addrmv-btn-cont">
                                                                            {connStrings.length - 1 === i && connStrings.length <= values.key_param.length-1 && <button style={{ marginLeft: "5px" }} onClick={SetKeyvalue(values.key_param.length)}>+</button>}
                                                                        </div>                                                                       
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    )
                                            })}
                                            </div> */}
                                    <div className='overallConTab'>
                                        <table className='table'>
                                            <thead>
                                                <tr className='tr'>
                                                    {/* <th className='th'>S.No</th> */}
                                                    <th className='th'>Key Parameter</th>
                                                    <th className='th'>Key Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {previewtxt && <p style={{ marginTop: "60px", textAlign: "center" }}>Please select the connection name</p>}
                                                {!previewtxt && dTransformconfigdt.map((values) => {
                                                    if (newtconfigname === values.id) return (
                                                        <tr className='' style={{ border: "1px solid transparent" }}>
                                                            <td disable>
                                                                {values.key_param.map((values, index) => { return (<input className='td' style={{ display: 'flex', textAlign: "center", width: "330px", padding: '10px' }} disabled name='keyParams' defaultValue={(values.keyPrams)} />) })}
                                                            </td>
                                                            <td>
                                                                {configStrings.map((x, i) => {
                                                                    return (
                                                                        <div key={i} style={{ textAlign: "center", display: "flex", marginRight: '-10px' }}>
                                                                            <input className='td' style={{ display: 'flex', textAlign: "center", width: "250px", padding: "10px" }} placeholder="Enter the value" disabled={disable} name='keyValues' value={x.keyValues} onChange={(e) => { handleKeyValues(e, i) }} />
                                                                            <div className="addrmv-btn-cont">
                                                                                {configStrings.length - 1 === i && configStrings.length <= values.key_param.length - 1 && <button style={{ marginLeft: "5px" }} onClick={SetKeyvalue(values.key_param.length)}>+</button>}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>


                                </Form.Item>
                            </Col>


                        </Row>
                        <div className='newconndtls-btn-cont'>
                            <Button type='primary' className='newconndtl-back-btn' onClick={Previouspage}>Back</Button>
                            <Button htmlType='submit' type='primary' ref={btnRef} loading={loading} className={toggle === false ? 'newconfigdtl-create-btn' : 'newconfigdtl-TestValid-btn1'} onClick={CreateConfigurationDetail}>submit</Button>
                            {toggle == true && (
                                <Button htmlType='submit' type='primary' className='newconndtl-create' onClick={CreateConfigDetail}>Create</Button>
                            )}
                        </div>
                    </Form>
                </div>
                }
            </div>
        </>
    )
}