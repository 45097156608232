import React from "react";
import { Modal } from "antd";
import { Table } from "antd";

const MonitorSchemaPopupTable = [
  {
    title: "S.No.",
    dataIndex: "sNo",
    width: "auto",
    onFilter: (value, record) => {
      return String(record.name).toLowerCase().includes(value.toLowerCase());
    },
  },

  {
    title: "Run ID",
    dataIndex: "runId",
    width: "auto",
  },
  {
    title: "Running Status",
    dataIndex: "runningStatus",
    width: "auto",
  },
  {
    title: "Schedule Name",
    dataIndex: "scheduleName",
    width: "auto",
  },
  {
    title: "Config Name",
    dataIndex: "configName",
    width: "auto",
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    width: "auto",
  },
  {
    title: "End Date",
    dataIndex: "endDate",
    width: "auto",
  },
];

//Mock data for Modal popup

const monitorDataFilterData = [
  {
    sNo: 1,
    runId: 10,
    runningStatus: "failed",
    scheduleName: "schedule Name",
    configName: "sqlserver to snowflake",
    startDate: "2023-09-08",
    endDate: "2023-09-11",
  },
  {
    sNo: 2,
    runId: 20,
    runningStatus: "failed",
    scheduleName: "schedule Name",
    configName: "sqlserver to snowflake",
    startDate: "2023-09-08",
    endDate: "2023-09-11",
  },
  {
    sNo: 3,
    runId: 30,
    runningStatus: "failed",
    scheduleName: "schedule Name",
    configName: "sqlserver to snowflake",
    startDate: "2023-09-08",
    endDate: "2023-09-11",
  },
  {
    sNo: 4,
    runId: 40,
    runningStatus: "failed",
    scheduleName: "schedule Name",
    configName: "sqlserver to snowflake",
    startDate: "2023-09-08",
    endDate: "2023-09-11",
  },
  {
    sNo: 5,
    runId: 50,
    runningStatus: "failed",
    scheduleName: "schedule Name",
    configName: "sqlserver to snowflake",
    startDate: "2023-09-08",
    endDate: "2023-09-11",
  },
];
const data = monitorDataFilterData.map((val) => {
  return {
    sNo: val.sNo,
    runId: val.runId,
    runningStatus: val.runningStatus,
    scheduleName: val.scheduleName,
    configName: val.configName,
    startDate: val.startDate,
    endDate: val.endDate,
  };
});

const monitorGetData = (page, pageSize) => {
  return data.slice((page - 1) * pageSize, page * pageSize);
};

const MonitorSchemaModal = ({ isModalOpen, toggleModal }) => {
  return (
    <>
      <Modal
        title="Description"
        open={isModalOpen}
        onOk={toggleModal}
        onCancel={toggleModal}
        width={1000}
        footer={false}
      >
        <Table
          className="Connection_Table"
          columns={MonitorSchemaPopupTable}
          dataSource={monitorGetData(1, 7)} //Mock data
          pagination={true}
        />
      </Modal>
    </>
  );
};

export default MonitorSchemaModal;
