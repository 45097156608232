import React, { useEffect, useState } from 'react'
import '../../Visual.css'
import { Row, Button, Form, Switch, Input, Col, DatePicker, List, AutoComplete } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import {DVisualreportPost,DVisualreportAddForm} from '../../../../../redux/reducer/Visualization/DVisualReport'
console.log(DVisualreportPost);
// console.log(PplDtlAddForm);
// console.log(reportPost)
// import Item from 'antd/lib/list/Item';

function VReport() {

  const dispatch = useDispatch()
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';

  const [visualReport,setVisualReport]=useState({
    configDetailId:'',
    DvisualReportName:'',
    StartDate:'',
    EndDate:'',
    WorkbookName:'',
    ReportId:'',

    // status:false,
  })
  
console.log(visualReport);

  const handleChange=(e)=>{
    setVisualReport({...visualReport,[e.target.name]:e.target.value})
  }
  const [formList, setFormList] = useState(false);

  const [formSearch, setFormSerach] = useState('');

  const visualReportData= useSelector((state)=> state.DVisualdt.DvisualdtData)
  const addform = useSelector((state)=>state.DVisualreport.Vreport.DVisualreportAddForm)
  const Search = useSelector((state)=>state.DVisualreport.Vreport.Search)

  const handleSearch = (e) => {
    setFormSerach(e.target.value)
    e.target.value.length > 0 ? setFormList(true) : setFormList(false)
  }




  const filteredData = visualReportData.filter((val) => {
    if (val.is_active === true) {
      return val
    }
  })
  console.log(filteredData)

//   const sqlFilterData = sqlExtractData.filter((val) => {
//     if (val.is_active === true) {
//       return val
//     }
//   })

  const data = filteredData.filter((val) => {
    console.log(val.dvisual_config_detail)
    if (formSearch === "") {
      return val;

    } else if (val.dvisual_config_detail.toString().toLowerCase().includes(formSearch.toLowerCase())) {
      return val
    }
  }).map((val) => {
    return {
      id: val.id,
      dvisual_config_detail: val.dvisual_config_detail,
      status: val.is_active,
    }
  })

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day')
  };
  const datePickerOnChange = (dates, dateStrings) => {
    setVisualReport({...visualReport,StartDate:dateStrings[0],EndDate:dateStrings[1]})
  }

  const addForm = () => {
    dispatch(DVisualreportPost({
      dvisual_config_detail_id: visualReport.ReportId,
      dvisual_report_name: visualReport.reportname,
      start_date: visualReport.StartDate,
      end_date: visualReport.EndDate,
      workbook_name: visualReport.WorkbookName,
    }))

  }

  return (

    <div className='reportCreate_overall'>

      {Search && <div className='report_Search_Parent_Container'>

        <Input.Search className='report_Add_Search_Input' onChange={handleSearch} placeholder='Search Configuration detail Names' enterButton />

        {formList && <div className='report_Add_Search_Sugg_Box'>

          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item onClick={() => {
                // setPipeline_name(item.pipeline_name)
                setVisualReport({...visualReport,ReportId:item.id,dvisual_config_detail:item.dvisual_config_detail})
                // setPipelineId(item.id)
                // setpipeDtlSerach(false)
                // setCreateForm(true)
                setFormList(false)
                dispatch(DVisualreportAddForm({Search:false, DVisualreportAddForm:true}))
                
              }} className='report_Search_List' style={{ height: "60px", cursor: 'pointer', userSelect: 'none',textTransform:"capitalize" ,fontFamily: "Nunito" }}>
                <List.Item.Meta
                  title={<p className='report_Search_List_Title_text'>{item.dvisual_config_detail}</p>}
                  description={<p className={item.status === true ? "Status_Active_Text" : "Status_InActive_Text"}>{item.status === true ? "Active" : "In_Active"}</p>}
                />
              </List.Item>
            )}
          />

        </div>}

      </div>}

      {addform && <Form name="basic" autoComplete="off" layout='vertical' size='medium' style={{ fontFamily: "Nunito" }}>
        <Row gutter={[0, 0]} >

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 11, offset: 0 }}>
            <Form.Item
              style={{ width: "100%" }}
              label="Report Name"
              name="reportname"
              rules={[
                {
                    required: true,
                  message: 'Please Enter Report Name',
                },
              ]}
            >
              <Input style={{textTransform:"capitalize"}}  className="report-Input" name='reportname' onChange={handleChange}  />
            </Form.Item>
          </Col>

          <Col lg={{ span: 11, offset: 2 }}>
            <Form.Item
              label="Configuration Detail Name"
              name=" configurationdetail"
            //   rules={[
            //     {
            //       required: true,
            //       message: 'Please Enter configuration Detail Name',
            //     },
            //   ]}
            >
              <Input style={{textTransform:"capitalize"}}  className="report-Input" name='configurationdetail' defaultValue={visualReport.dvisual_config_detail} disabled />
            </Form.Item>
          </Col>

          <Col lg={{ span: 11, offset: 0 }}>

            <Form.Item
              label="Description"
              name="description"
            >
              <Input style={{textTransform:"capitalize"}}  className="report-Input" name='description' onChange={handleChange} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 11, offset: 2 }}>
            <Form.Item
              label="WorkBook Name "
              name="workBookName"
              rules={[
                {
                  required: true,
                  message: 'Please Enter WorkBook Name',
                },
              ]}
            >
              <Input style={{textTransform:"capitalize"}}  className="report-Input" name='WorkbookName' onChange={handleChange} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
            <Form.Item
              label="Start Date to End Date"
              name="StartDateToEndDate"
              rules={[
                {
                  required: true,
                  message: 'Please Select End Date',
                },
              ]}
            >
              <RangePicker disabledDate={disabledDate} defaultValue={[moment()]} onChange={datePickerOnChange} dateFormat style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 0 }}>
            <Form.Item
              label="Extract SQL Name"
              name="extract sql name"
            >
              <Switch defaultChecked={false} onChange={sqlExtractSwitch} />
            </Form.Item>
          </Col> */}

          {/* {extractstatus && <Col lg={{ span: 11, offset: 8 }}>
            <Form.Item
              key={visualReport.sqlExtractName}
              label="SqlExtract Name"
              name="sqlExtractName">
              {sqlSearchInput && <Input style={{textTransform:"capitalize"}}  onChange={sqlExtractHandleChange} />}
              {sqlNameDisplay && <Input style={{textTransform:"capitalize"}}   defaultValue={visualReport.sqlExtractName} name="sqlExtractName" onChange={sqlNameDisplayHandleChange} />}
            </Form.Item>
            {sqlFormList && <div className='SqlExtract-Form-List'>
              <List
                itemLayout="horizontal"
                dataSource={sqlData}
                renderItem={item => (
                  <List.Item onClick={() => {
                    setVisualReport({...visualReport,sqlExtractName:item.tittle,sqlExtractId:item.id})
                    // setSqlExtractId(item.id)
                    setSqlFormList(false)
                    setSqlSearchInput(false)
                    setSqlNameDisplay(true)
                  }} className='report_Search_List' style={{ height: "60px", cursor: 'pointer', userSelect: 'none',textTransform:"capitalize" }}>
                    <List.Item.Meta
                      title={<p className='report_Search_List_Title_text'>{item.tittle}</p>}
                      description={<p className={item.status === true ? "Status_Active_Text" : "Status_InActive_Text"}>{item.status === true ? "Active" : "In_Active"}</p>}
                    />
                  </List.Item>
                )}
              />
            </div>}
          </Col>} */}

          {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 24, offset: 0 }}>
            <Form.Item
              label="Status"
              name="status"
            >
              <Switch name="status" onChange={statusSwitch} />
            </Form.Item>
          </Col> */}

          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 10, offset: 0 }}>
            <Button type="primary" onClick={() => {
              // setpipeDtlSerach(true)
              // setCreateForm(false)
              dispatch(DVisualreportAddForm({Search:true, DVisualreportAddForm:false}))
              setFormSerach('')
              setFormList(false)
            }} className='report_Back_Button'>Back</Button>
          </Col>
          <div style={{display:"flex",width:"97%",justifyContent:"flex-end"}}>
          {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 10, offset: 4 }}> */}
            <Button type="primary" onClick={addForm} htmlType="submit" className='report_Add_Button'> Create </Button>
          {/* </Col> */}
          </div>
        </Row>
      </Form>}
    </div>
  )

}

export default VReport