import React, { Fragment, useEffect, useState } from 'react'
import Header from "../../components/header/Header";
import CommonTable from '../../components/table/CommonTable';
import Footer from "../../components/footer/Footer";
import Flatten from "../../components/modal/flatten/Dflatten/Add/Dflatten"
import { useSelector, useDispatch } from 'react-redux'
import { DflattenCreatePop, DFlattenGet } from '../../redux/reducer/Dflatten/DFlatten'
import { DFlattendtCreatePop } from '../../redux/reducer/Visualization/DVisualizationDetail'
import { DFlattenreportCreatePop } from '../../redux/reducer/Visualization/DVisualReport'
import FlattenAdd from '../../components/modal/flatten/Dflatten/Add/Dflatten'
import FlattendtAdd from '../../components/modal/visualization/VconfigDeatail/Add/VconfigdtAdd'
import FlattenReportAdd from '../../components/modal/visualization/Vreportlist/Add/ReportlistAdd'
import { Drawer, Button, Space } from 'antd';
import { Switch } from 'antd';
import * as Fi from 'react-icons/fi';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import moment from 'moment'
function DFlatten() {

    const dispatch = useDispatch()
    const SearchData = useSelector((state) => state.Header.Search.value)
    const footerPage = useSelector((state) => state.Header.Footer.page)
    const footerPageSize = useSelector((state) => state.Header.Footer.pagesize)

    const DFlattenGetAll = useSelector((state) => state.DFlatten.dFlattenGetData)
    console.log(DFlattenGetAll);
    const FlattenCreate = useSelector((state) => state.DFlatten.Fmodal.DflattenCreatePop)

    useEffect(() => {
        dispatch(DFlattenGet(true))
    }, [])


    const FlattenColumn = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: '80px',
            // render: (text, object, index) => { return index + 1 },
            render: (text, object, index) => { return (footerPage - 1) * footerPageSize + index + 1 },
            // filteredValue: [searchData],
            // onFilter: (value, record) => {
            //     return String(record.name).toLowerCase().includes(value.toLowerCase());
            // },
        },
        {
            title: 'File Name',
            dataIndex: 'name',
            width: '230px',
        },
        {
            title: 'File Type',
            dataIndex: 'type',
            // sorter: (a, b) => { return a.config_name.localeCompare(b.config_name) },
            width: '250px',
        },
        {
            title: 'Description',
            dataIndex: 'desc',
            // sorter: (a, b) => { return a.config_name.localeCompare(b.config_name) },
            width: '250px',
        },
        {
            title: 'Start Date',
            dataIndex: 'sdate',
            // sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
            width: '220px'
        },
        {
            title: 'End Date',
            dataIndex: 'edate',
            // sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
            width: '220px'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '70px'
        },
    ];

    const dflatten = DFlattenGetAll.map((value) => {
        console.log(value)
        console.log(value.File_Name)
        return (
            {
                name: value.File_Name,
                type: value.File_Type,
                desc: value.Description,
                sdate: moment.utc((value.start_date)).format('MM-DD-YYYY'),
                edate: moment.utc((value.end_date)).format('MM-DD-YYYY'),
                // active: (<Switch checked={value.is_active === true ? true : false} />),
                action: (
                    <div className='Action_Icons'>
                        <Ai.AiOutlineDownload
                            size={18}
                            onClick={() => {
                                // Handle download action
                            }}
                            style={{ cursor: 'pointer', marginRight: '10px' }}
                        />
                    </div>
                )

            }
        )
    })

    const DataFlatten = (page, pageSize) => {
        return dflatten.slice((page - 1) * pageSize, page * pageSize);
    };
    console.log(dflatten)

    const FlattenCreateModal = () => {
        dispatch(DflattenCreatePop())
    }

    return (
        <Fragment>
            <Header />
            <CommonTable FlattenColumn={FlattenColumn} FlattenData={DataFlatten(footerPage, footerPageSize)} />
            <Drawer title=" Create D-Flatten-Configuration" open={FlattenCreate} maskClosable={false} onClose={FlattenCreateModal} ariaHideApp={false} width='100vh'
                footer={[
                    <div style={{ padding: '12px 50px', display: 'flex', justifyContent: 'flex-end', margin: '0% auto', marginTop: '8px' }} >

                    </div>]}
                footerStyle={{ backgroundColor: '#f5f5fd' }}
                extra={
                    <Space >
                        <Button onClick={FlattenCreateModal}>Cancel</Button>
                    </Space>
                }

                key={FlattenCreate ? 'drawer-open' : 'drawer-closed'} >
                <Flatten />
            </Drawer>
            <Footer />
        </Fragment>
    )
}

export default DFlatten